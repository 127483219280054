<template>
<div class="com-input">
    <el-input
            :class="color?'color':''"
            v-if="data.type=='number'"
            type="number"
            v-model="input_value"
            :placeholder="data.placeholder||''" :name='data.id||""'
            :disabled="data.disabled" @focus.stop="$emit('focus')
            ;allow_input_fucus=true" @blur.stop="$emit('blur')
            ;input_blur()" @input="search_local"
            :readonly="readonly"
            :clearable="clearable">
        <div v-if="data.unit&&data.unit!=''" slot="suffix" class="el-input__icon com-input-unit">{{data.unit}}</div>
    </el-input>
    <el-input
            :class="color?'color':''"
            v-else-if="data.type=='password'"
            type="password"
            v-model="input_value"
            :placeholder="data.placeholder||''" :name='data.id||""'
            :disabled="data.disabled" @focus.stop="$emit('focus')
            ;allow_input_fucus=true" @blur.stop="$emit('blur')
            ;input_blur()" @input="search_local"
            :readonly="readonly"
            :clearable="clearable">
        <div v-if="data.unit&&data.unit!=''" slot="suffix" class="el-input__icon com-input-unit">{{data.unit}}</div>
    </el-input>
    <el-input
           :class="color?'color':''"
            v-model="input_value"
            v-else
            :placeholder="data.placeholder||''"
            :name='data.id||""'
            :disabled="data.disabled"
            @focus.stop="$emit('focus')
            ;allow_input_fucus=true"
            @blur.stop="$emit('blur');input_blur()"
            @input="search_local"
            :readonly="readonly"
            :clearable="clearable">
        <div v-if="data.unit&&data.unit!=''" slot="suffix" class="el-input__icon com-input-unit">{{data.unit}}</div>
    </el-input>
    <span v-if="data.rightTitle" class="title">
        {{data.rightTitle}}
    </span>
    <div v-if="search" class="icon-search" @click='input_search'>
        <img src='../../assets/img/search.png'/>
    </div>
    <transition name="el-zoom-in-top">
        <div class="el-select-dropdown el-popper search-result" v-if='map_search_result.length>0 && data.expand && data.expand.allow_address_specific && allow_input_fucus'>
            <div class="el-scrollbar">
                <div class="el-select-dropdown__wrap el-scrollbar__wrap el-scrollbar__wrap--hidden-default">
                    <ul class="el-scrollbar__view el-select-dropdown__list">
                        <!--  item.district+  -->
                        <li class="el-select-dropdown__item" v-for="(item,index) in map_search_result" :key="index" @click.stop="search_result_click(item)"><span @click.stop="search_result_click(item)">{{item.name}}</span></li>
                    </ul>
                </div>
            </div>
            <div x-arrow="" class="popper__arrow" style="left: 35px;"></div>
        </div>
    </transition>
    <div class="checkout-failed" v-show="errorsInfo!=''">
        {{errorsInfo}}
    </div>

</div>
</template>

<script>
export default {
    name: 'comInput',
    props: {
        data: {
            type: Object,
            default: function () {
                return {
                    name: '',
                    placeholder: ''
                };
            }
        },
        value: {
            default: '',
        },
        readonly: {
            default: false
        },
        clearable: {
            type: Boolean,
            default: false
        },
        search:{
            default: false
        },
        errorsInfo:{
            type:String,
            default:''
        },
        color:{
            type: Boolean,
            default: false
        }
    },

    data: function () {
        return {
            input_value: '',
            edit_flag: false,
            data_format_flag: false,
            temp_input_value: '',
            autoComplete: null,
            placeSearch: null,
            map_search_result: [],
            allow_input_fucus: false
        }
    },
    computed: {
        disabled: function () {
            if (this.edit_flag) {
                return this.data.disabled || false;
            } else {
                return this.data.new_disabled || false;
            }
        }
    },
    created() {
        this.edit_flag = this.$route && this.$route.query && this.$route.query.type && this.$route.query.type === 'edit';
        this.input_value_init();
    },
    mounted() {
        this.is_address_specific();
    },
    methods: {
        input_value_init() {
            //身份证格式化
            if (this.input_value != this.value) {
                
                this.input_value = this.value;
              
            }

        },
        is_address_specific() {
            if (this.data.expand && this.data.expand.hasOwnProperty("allow_address_specific")) {
                this.$nextTick(() => {
                    this.newmap();
                });
            }
        },
        //创建地图
        newmap() {
            //关键字搜索
            AMap.plugin("AMap.Autocomplete", () => {
                this.autoComplete = new AMap.Autocomplete();
            });
        },
      

        //搜索
        search_local() {
            if (this.data.expand && this.data.expand.allow_address_specific) {
                let vue = this;
                if (this.input_value != '') {
                    // this.set_searchCity(this.data.expand.allow_address_specific)
                    this.autoComplete.search(this.data.expand.allow_address_specific + this.input_value, function (status, result) {
                        // 搜索成功时，result即是对应的匹配数据
                        if ((result.info = "OK")) {
                            if (result.tips) {
                                vue.map_search_result = result.tips.slice(0);
                            }
                        }
                    });
                } else {
                    vue.map_search_result = [];
                }
            }
        },
        //地图搜索结果
        search_result_click(item) {
            this.input_value = item.name;
            this.allow_input_fucus=false;
        },
        //value填充
        input_blur() {
            if(this.search_local()){
                 this.allow_input_fucus = false;
            }
        },
        input_search(){
            this.$emit('input_search', this.input_value);
        }
        
    },
    watch: {
        input_value: function (n) {
            console.log(typeof n, n)
            this.$emit('input', this.input_value);
            this.$emit('get_input', this.input_value);
        },
        'value': function () {
            this.input_value_init();
        }
    }
}
</script>

<style lang="less">
.com-input {

    width: 100%;
    // height: 22px;
    height: 30px;
    display: inline-block;
    position: relative;
.icon-search{
    position: absolute;
    top: 4px;
    right: 0;
    font-size: 16px;
    width:30px;
    height:24px;
    box-sizing:border-box;
    display:flex;
    align-items:center;
    justify-content:center;
    border-left:1px solid #fff;
    img{
        display:inline-block;
        width:22px;
        height:22px;
    }
}   
    .color.el-input{
        input {
           background:rgba(241,241,241,1); 
           border:none;
        }
    }
    .el-input {
        height: 100%;
        input {
            height: 100%;
            /*border-radius: 0;*/
            padding: 0 5px;
            line-height: 1;
            // border:1px solid rgba(0,62,100,1);
            // background: rgba(0,62,100,0.5);
            background: none;
            color:#24BBEE;
            color:#000;
            border:1px solid rgba(233,233,233,1);
            // background:rgba(241,241,241,1);
        }

        input::-webkit-input-placeholder, textarea::input-placeholder {
            color: red; 
        } 
        input:-moz-placeholder{
            color: red; 
        }
    }
    .el-input.com-search-input{
        input{
            padding: 0 24px;
        }
    }
    .el-input.is-disabled{
        input{
            background: none;
            // border:1px solid rgba(0,62,100,1);
        }
    }
    .el-input--suffix {
        input {
            padding-right: 30px;
        }
    }

    .com-input-unit {
        width: auto;
        // line-height: 22px;
        line-height: 30px;
        text-align: right;
        color: #333;
    }

    .el-select .el-input__inner:focus {
        // border-color: #108CEE;
    }

    .el-input.is-active .el-input__inner,
    .el-input__inner:focus {
        // border-color: #108CEE;
    }

    .el-input {
        font-size: 12px;
    }

    .title {
        position: absolute;
        right: -95px;
        top: 0px;
        // line-height: 22px;
        line-height: 30px;
        cursor: pointer;
        color: #0f8ced;
    }

    .search-result {
        width: 100%;
        position: absolute;
        z-index: 1999;
        overflow: hidden;
    }
}
</style>
