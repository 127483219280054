/****
 * 常用工具函数合集
 * 2021-11-11
 */


/**
 * 深度克隆一个对象
 * @param {*} obj
 */
//深度克隆
export const deepClone = function(obj, cache = []) {
    // 判断原对象是否存在
    if (obj === null || typeof obj !== 'object') {
        return obj
    }
    const hit = cache.find(c => c.original === obj)
    if (hit) {
        return hit.copy
    }

    const copy = Array.isArray(obj) ? [] : {}
    cache.push({
        original: obj,
        copy
    })

    Object.keys(obj).forEach(key => {
        copy[key] = deepClone(obj[key], cache)
    })

    return copy
}

/*******
 * 判断类型
 */

export const typeOf = (obj) => {
    const toString = Object.prototype.toString;
    const map = {
        '[object Boolean]': 'boolean',
        '[object Number]': 'number',
        '[object String]': 'string',
        '[object Function]': 'function',
        '[object Array]': 'array',
        '[object Date]': 'date',
        '[object RegExp]': 'regExp',
        '[object Undefined]': 'undefined',
        '[object Null]': 'null',
        '[object Object]': 'object'
    };
    return map[toString.call(obj)];
}