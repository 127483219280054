<template>

    <div class="com-from" :id="`com_from_${data.id}`" v-if="data.show===undefined?true:data.show">
        <template v-if="data.noname!==undefined&&data.noname&&data.slot&&data.slot!=''">
            <slot :name="data.slot"></slot>
        </template>
        <template v-else>
            <div class="row-one">
                <div class="from-name" :style="`width:${nameWidth}`" v-if="data.name!=''">
                    <span v-if="data.check_type&&data.check_type.match('required')" class="conent-required">*</span>
                    <span>{{data.name}}</span>
                </div>
                <template v-if="data.slot&&data.slot!=''">
                    <slot :name="data.slot" :data="data"></slot>
                </template>

                <template v-else>
                    <com-input class="from-content" :style="`width:${fromWidth}`" v-if="data.type==='password'"
                              v-model="input_value"
                              :data="data"
                    ></com-input>
                    <com-input class="from-content" :style="`width:${fromWidth}`" v-else-if="data.type==='number'"
                              v-model="input_value"
                              :data="data"
                    ></com-input>
                    <com-input class="from-content" :style="`width:${fromWidth}`" v-else-if="data.type==='input'"
                              v-model="input_value"
                              :data="data"
                    ></com-input>
                    <com-textarea class="from-content" :style="`width:${fromWidth}`" v-else-if="data.type==='textarea'"
                                 v-model="input_value"
                                 :data="data"
                    ></com-textarea>
                    <com-select class="from-content" :style="`width:${fromWidth}`" v-else-if="data.type==='select'"
                               v-model="input_value"
                               :class="data.expand&&data.expand.multiple==true ? 'aotu-height' : ''"
                               :params='data.expand ? data.expand.params : defaultSelectParams'
                               :data="data"
                               @select-option="select_change"
                    ></com-select>
                    <com-select-radio class="from-content" :style="`width:${fromWidth}`" v-else-if="data.type==='select_radio'"
                                     v-model="input_value"
                                     :data="data"
                                     @change="select_change"
                    ></com-select-radio>
                    <comDate class="from-content" :style="`width:${fromWidth}`" v-else-if="data.type==='date'"
                            v-model="input_value" :type="(data.expand && data.expand.type) || 'date'"
                            :data="data">
                    </comDate>
                    <comDate class="from-content" :style="`width:${fromWidth}`" v-else-if="data.type==='dateRange'"
                            v-model="input_value" :type="(data.expand && data.expand.type) || 'daterange'"
                            :data="data">
                    </comDate>
                    <comTimePicker class="from-content" :style="`width:${fromWidth}`" v-else-if="data.type==='time'"
                                  v-model="input_value"
                                  :data="data">
                    </comTimePicker>
                    <comCascader class="from-content" :style="`width:${fromWidth}`" v-else-if="data.type==='cascader'"
                                v-model="input_value"
                                @select-option="select_change"
                                :data="data">
                    </comCascader>
                    <comImg class="from-content" :style="`width:${fromWidth}`" v-else-if="data.type==='img'"
                           v-model="input_value"
                           @expandData="expandData"
                           :data="data">
                    </comImg>
                    <comFile class="from-content" :style="`width:${fromWidth}`" @delete_file = "delete_file" v-else-if="data.type==='file'"
                            v-model="input_value"
                            :data="data">
                    </comFile>
                    <checkGroup class="from-content" :style="`width:${fromWidth}`" @delete_file = "delete_file" v-else-if="data.type==='check_box'"
                            @change='checkchange'
                            :data="data">
                    </checkGroup>
                    <comDateTime class="from-content" :style="`width:${fromWidth}`" v-else-if="data.type==='dateTime'"
                        v-model="input_value" :data="data" @dataTimeChange="dataTimeChange">
                    </comDateTime>
                </template>
                <!-- 表单右侧插槽 -->
                <template v-if="data.expand&&data.expand.right_slot&&data.expand.right_slot!=''">
                    <slot :name="data.expand.right_slot" :data="data"></slot>
                </template>

            </div>
            <div class="row-two">
                <div class="from-name" v-if="data.name!=''" :style="`width:${nameWidth}`"></div>
                <div class="out_palceholder" v-if="data.out_palceholder&&data.out_palceholder!=''"></div>
                <div class="checkout-failed" v-show="errorsInfo!=''">
                    {{errorsInfo}}
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    export default {
        name: 'comFrom',
        props: {
            height: {
                type: String,
                default: '65px'
            },
            nameWidth: {
                type: String,
                default: '100px',
            },
            fromWidth: {
                type: String,
                default: '355px',
            },
            data: {
                type: Object,
                default: function () {
                    return {
                        id: 'company_name',
                        name: '单位名称:',
                        value: '',
                        type: 'input',
                        check_type: 'required',
                        placeholder: '请填写单位名称',
                        unit: '',
                        disabled: false,
                        options: []
                    }
                }
            },
            value: '',
            errorsInfo: {
                type: String,
                default: ''
            }
        },
        data: function () {
            return {
                new_flag: true,  //新增标准，true表示页面为新增页面
                input_value: '',
                check_value:'',
                defaultSelectParams: {label:'label',value:'value'}
            }
        },
        created() {
            this.input_value = this.value;
        },
        mounted() {
        },
        methods: {
            dataTimeChange(value){
                this.input_value = value
            },
            /** 选择框改变函数 */
            select_change(data) {
                this.$emit('from-input', {
                    type: 'select_change',
                    data: data,
                    source: this.data
                })
            },
            delete_file(val){
                this.$emit('delete_file', val);
            },
            checkchange(val){
                this.$emit('input', val);
                this.$emit('comchange', val);
                // this.input_value = val;
                console.log(val,'cjeck')
            },
            expandData(data){
                this.$emit('expandData', data);
            }
        },
        watch: {
            "input_value": function () {
                console.log("value-change")
                this.$emit('comchange', this.data, this.input_value);
                this.$emit('input', this.input_value);
            },
            "value": function () {
                this.input_value = this.value;
            }
        }
    }
</script>
<style lang='less'>
    .com-from {
        .el-input__inner {
            border: 1px solid #d6d9e0;
        }
        .from-content.aotu-height{
            height:auto;
            min-height: 34px;
        }
    }
</style>
<style lang='less' scoped>
    .com-from {
        color: #000;
        .row-two {
            height: 15px;
        }
        .row-one, .row-two {
            display: flex;
            align-items: center;
        }
        .from-name {
            flex: none;
            align-self: flex-end;
            width: 100px;
            // height: 22px;
            // line-height: 22px;
            height: 34px;
            line-height: 34px;
            text-align: left;
            padding-right: 10px;
            font-size: 14px;

            .conent-required {
                color: #000;
                line-height: 20px;
            }
        }
        .from-content {
            width: 380px;
            font-size: 12px;
        }
        .checkout-failed {
            font-size: 12px;
            color: red;
        }
    }

    .fireSupUnit-system {
        .com-from {
            color: #81d5ff;
        }
    }
</style>
