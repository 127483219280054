<template>
  <div class="login">
    <div class="top-logo-title">
      <img :src="logo" alt="">
      <span class="title">外加剂原材料集中采购平台</span>
      
    </div>
    <div class="form-login">
      <div class="top-title">
        用户登录
      </div>
      <div class="mid-form">
        <div class="left-logo">

        </div>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="0px" class="login-ruleForm">
          <el-form-item prop="userName" class="qn-input__inner">
            <span class="icon"><i class="el-icon-user"></i></span>
            <el-input class="qn-user-input" v-model.trim="ruleForm.userName" ref="userName"
              placeholder="请输入用户名"></el-input>
          </el-form-item>
          <el-form-item prop="passWord" class="qn-input__inner">
            <span class="icon"><i class="el-icon-lock"></i></span>
            <el-input class="qn-Passwd-input" type="password" placeholder="请输入密码" v-model.trim="ruleForm.passWord"
              @keyup.enter.native="submitForm('ruleForm')"></el-input>
          </el-form-item>
          <!-- <div class="login-operate">
            <div class="operate">
              <div class="left">
                <el-checkbox v-model="isRemember">记住密码</el-checkbox>
              </div>
              <div class="right" @click="go_forget_pass">忘记密码</div>
            </div>
          </div> -->
          <div class="login-btn">
            <el-button type="primary" @click="submitForm('ruleForm')" @keyup.enter.native="submitForm('ruleForm')">
              登录
            </el-button>
          </div>
        </el-form>
      </div>
      <div class="bot-submit"></div>
    </div>
  </div>
</template>
<script>
import logo from "../../assets/img/logo-white.png"
import { login } from "../../api/index.js";
export default {
  name: "login",
  data() {
    return {
      logo,
      ruleForm: {
        userName: "",
        passWord: "",
      },
      rules: {
        userName: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        passWord: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      isRemember: false,
      userInfo:JSON.parse(sessionStorage.userInfo || '{}')
    };
  },
  created() {
    // this.remember_password_init();
    if(this.userInfo.token){
      this.$router.push("/index")
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
         if (valid) {
           login(this.ruleForm).then(
             (res) => {
               console.log(res);
               if (res.code == 200) {
                 sessionStorage.setItem("userInfo", JSON.stringify(res.result));
                 this.remember_password_fn();
                 this.$router.push({ path: "/index" });
               }
             },
             (error) => {

             }
           );
         } else {
           return false;
         }
      });
    },
    go_forget_pass() {
      this.$router.push({ path: "/change_pass" });
    },
    remember_password_fn() {
      if (this.isRemember) {
        sessionStorage.setItem(
          "changyoupassword",
          JSON.stringify({
            user_name: this.ruleForm.Username,
            user_pwd: this.ruleForm.Passwd,
          })
        );
      } else {
        sessionStorage.removeItem("changyoupassword");
      }
    },
    //记住密码初始化
    remember_password_init() {
      this.isRemember = false;
      if (sessionStorage.getItem("changyoupassword")) {
        this.ruleForm.Username = JSON.parse(
          sessionStorage.getItem("changyoupassword")
        ).user_name;
        this.ruleForm.Passwd = JSON.parse(
          sessionStorage.getItem("changyoupassword")
        ).user_pwd;
        this.isRemember = true;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.login {
  width: 100%;
  height: 100%;
  position: relative;
  background: #303133;
  background-image: url("../../assets/img/back.png");
  background-size: 100% 100%;
  .top-logo-title{
    position: absolute;
    top: 5%;
    left: 3%;
    display: flex;
    justify-content: center;
    align-items: center;
    .title{
      font-size: 25px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #2F5886;
      line-height: 20px;
      margin-left: 10px;
      padding-left: 10px;
      border-left: 1px solid #fff;
    }
  }
  .form-login {
    position: absolute;
    top: 50%;
    right: 10%;
    transform: translate(0,  -50%);
    width: 350px;
    background: #fff;
    // border: 1px solid rgba(112,112,112,1);
    border-radius: 4px 4px 4px 4px;
    color: #fff;
    padding: 20px;
    padding-left: 20px;
    padding-right: 30px;
    padding-bottom: 15px;
    .top-title {
      width: 100%;
      height: 60px;
      text-align: center;
      color: #000;
      font-weight: bold;
      line-height: 80px;
      font-size: 25px;
    }
    .mid-form {
      width: 100%;
      height: calc(100% - 100px);
      font-size: 12px;
      display: flex;
      .left-logo{
        background: #ccc;
        width: calc(100% - 330px);
        height: 100%;
      }
      .login-ruleForm {
        width: 320px;
        margin: 0 auto;
        .qn-input__inner {
          width: 100%;
          height: 34px;
          margin-top: 25px;
          position: relative;
          .el-input__inner {

          }
          .el-form-item__content {
            width: 100%;
            height: 100%;
            .el-input {
              width: 100%;
              border-bottom: 1px solid #000;
              height: 100%;
              input {
                width: 100%;
                height: 100%;
              }
            }
            .icon {
              position: absolute;
              top: 0;
              left: 0;
              width: 34px;
              height: 34px;
              line-height: 34px;
              font-size: 18px;
              color: #000;
            }
          }
        }
        .login-operate {
          width: 100%;
          height: 12px;
          margin-top: 5px;
          color: #000;
          .operate {
            width: 264px;
            height: 100%;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            .left {
              height: 100%;
              line-height: 12px;
            }
            .right {
              cursor: pointer;
              height: 100%;
              line-height: 12px;
            }
          }
        }
        .login-btn {
          width: 100%;
          height: 44px;
          margin-top: 30px;
          margin-bottom: 20px;
          .el-button {
            width: 100%;
            height: 44px;
            opacity: 1;
            box-shadow: 0px 3px 6px 0px rgba(97, 97, 97, 0.4);
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>
<style lang='less'>
.login {
  .mid-form {
    .login-ruleForm {
      .qn-input__inner {
        .el-form-item__content {
          width: 100%;
          height: 100%;
          .el-input {
            input {
              width: 100%;
              height: 100%;
              padding: 0;
              padding-left: 42px;
              outline: none;
              border: none;
              -webkit-appearance: none;
              border-radius: 0;
              background: none;
              box-sizing: border-box;

            }
            input::-webkit-input-placeholder {
              /* WebKit browsers */

              // text-align: center;
              text-indent: 60px;
            }
            input:-moz-placeholder {
              /* Mozilla Firefox 4 to 18 */
              // color: rgba(217, 217, 217, 1);
            }
            input::-moz-placeholder {
              /* Mozilla Firefox 19+ */
              // color: rgba(217, 217, 217, 1);
            }
            input:-ms-input-placeholder {
              /* Internet Explorer 10+ */
              // color: rgba(217, 217, 217, 1);
            }
          }
          .el-form-item__error {
            width: 100%;
            text-align: center;
            padding-left: 9px;
            text-indent: -7px;
            box-sizing: border-box;
          }
        }
      }
      .qn-input__inner:first-child {
        .el-form-item__content {
          .el-input {
            input {
            }
            input::-webkit-input-placeholder {
            }
          }
          .el-form-item__error {
            text-indent: 3px;
          }
        }
      }
      input[type="checkbox"] {
        margin: 0;
        width: 12px;
        height: 12px;
      }
      .el-checkbox {
        .el-checkbox__label {
          color: rgba(217, 217, 217, 1);
        }
      }
    }
  }
}
</style>
