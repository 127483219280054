import {
    deepClone ,
    typeOf ,
} from './function'
export default {
    version: '0.0.1',
    install: function (Vue) {
      Vue.prototype.$deepClone = deepClone;
      Vue.prototype.$typeOf = typeOf;
    },
}