import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../views/login/login.vue'
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    // debugger
    if (onResolve || onReject) return originalPush.call(this, location)
    return originalPush.call(this, location).catch(err => console.log("router_err:",err))
}

const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function push (location) {
    if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
    return (originalReplace.call(this, location)).catch(err => console.log("router_err:",err))
}
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: login
  },
  {
    path: '/index',
    name: 'mainContent',
    component: () => import('../views/main/index.vue'),
    redirect:"/businessManage",
    children:[
      // 企业管理
      {
        path: '/businessManage',
        name: 'businessManage',
        component: () => import('../views/main/business-manage/businessManage.vue')
      },
      // 采购管理
      {
        path: '/purchaseManage',
        name: 'purchaseManage',
        component: () => import('../views/main/purchase-manage/purchaseManage.vue'),
        children:[
          { // 采购计划
            path: '/purchaseManage/plan',
            name: 'purchasePlan',
            component: () => import('../views/main/purchase-manage/purchase-plan/purchasePlan.vue'),
          },
          { // 采购计划
            path: '/purchaseManage/autoplan',
            name: 'purchaseAutoPlan',
            component: () => import('../views/main/purchase-manage/purchase-autoplan/purchaseAutoPlan.vue'),
          },
          { // 采购计划
            path: '/purchaseManage/xinyu',
            name: 'xinyu',
            component: () => import('../views/main/purchase-manage/purchase-autoplan/xinyudu.vue'),
          }
        ]
      },
      // 内容管理
      {
        path: '/contentManage',
        name: 'contentManage',
        component: () => import('../views/main/content-manage/main.vue'),
        children:[
          { // 须知
            path: '/contentManage/xuzhi',
            name: 'purchasePlan',
            component: () => import('../views/main/content-manage/contentManage.vue'),
          },
          { // 评价
            path: '/contentManage/evaluation',
            name: 'purchasePlan',
            component: () => import('../views/main/content-manage/evaluation.vue'),
          },
          { // 材料型号
            path: '/contentManage/materialAndModel',
            name: 'materialAndModel',
            component: () => import('../views/main/content-manage/materialAndModelManage.vue'),
          },
          { // 关于集采
            path: '/contentManage/jicaiAbout',
            name: 'contentManageAbout',
            component: () => import('../views/main/content-manage/contentManageAbout.vue'),
          },
          { // banner 管理
            path: '/contentManage/banner',
            name: 'bannerManage',
            component: () => import('../views/main/content-manage/bannerManege.vue'),
          },
        ]
      },
      // 过程管理
      {
        path: '/processManage',
        name: 'processManage',
        component: () => import('../views/main/process-manage/processManage.vue'),
        children:[
          { // 需求
            path: '/processManage/demand',
            name: 'demandManage',
            component: () => import('../views/main/process-manage/demand-manage/demandManage.vue'),
          },
          { // 报价
            path: '/processManage/quote',
            name: 'quoteManage',
            component: () => import('../views/main/process-manage/quote-manage/quoteManage.vue'),
          }
        ]
      },
      // 用户管理
      {
        path: '/userManage',
        name: 'userManage',
        component: () => import('../views/main/user-manage/userManage.vue'),
        children:[
          { // 用户列表
            path: '/userManage/user',
            name: 'userList',
            component: () => import('../views/main/user-manage/userList/uesrList.vue'),
          },
          { // 权限配置
            path: '/userManage/auth',
            name: 'authConfig',
            component: () => import('../views/main/user-manage/authConfig/authConfig.vue'),
          }
        ]
      },
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
