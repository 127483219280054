<template>
    <div class="com-date-time">
        <div class="item" v-for="(date, index) in tempValue" :key="index">
             <div class="item-flex">
                <el-select v-model="date.dateValue" placeholder="请选择" @change="change" class="date-select">
                   <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                   </el-option>
                </el-select>
            </div>
            <div class="item-flex">
                <el-time-select class="times-elect" v-model="date.timeValue" 
                    placeholder="选择时间" @change="change"></el-time-select>
            </div>
            <div class="seprate" v-if="index === 0 && tempValue.length > 1">
                至
            </div>
        </div>
       
    </div>
</template>
<script>
export default {
    name:"comDateTime",
    props:{
        value:{
            type:Array | String,
            default:""
        },
    },
    data(){
        return {
            options:[],
            tempValue:[]
        }
    },
    created(){
        this.generateDateOptions()
        this.valueInit()
    },
    computed:{
    },
    methods:{
        valueInit(){
            console.log("valueInit")
            this.tempValue = []
            let dealArr
            if(typeof this.value === "object"){
                dealArr = this.value
            }else{
                dealArr = [this.value]
            }
            dealArr.forEach(element => {
                console.log(element,"element")
                if(element){
                    let arr = element.split(" ")
                    let obj = {
                        dateValue: Number(arr[0]),
                        timeValue: arr[1],
                    }
                    this.tempValue.push(obj)
                }else{
                    this.tempValue.push({
                        dateValue:"",
                        timeValue:""
                    })
                }
                
            });
        },
        generateDateOptions(){
            this.options = []
            for(let i = 1; i< 31; i++){
                let obj = {
                    label: i,
                    value: i<10 ? ("0" + i) : i
                }
                this.options.push(obj)
            }
        },
        change(value){
            let data = this.dealDataToEmit()
            this.$emit("dataTimeChange", data);
        },
        dealDataToEmit(){
            if(this.tempValue.length == 1){
                return this.tempValue[0].dateValue + " " + this.tempValue[0].timeValue + ":00"
            }else if(this.tempValue.length == 2){
                return [this.tempValue[0].dateValue + " " + this.tempValue[0].timeValue  + ":00", this.tempValue[1].dateValue + " " + this.tempValue[1].timeValue + ":00"]
            }
        }
    },
    watch:{
        value:{
            handler(){},
            deep: true
        },
        tempValue:{
            handler(){},
            deep: true
        }
    }
}
</script>
<style lang="less" scoped>
.com-date-time{
    display: flex;
    .item{
       display: flex;
       .date-select{
           width: 50px;
           margin-right: 5px;
       } 
       .times-elect{
           width: 60px;
       }
       .item-flex{
           display: flex;
       }
       .seprate{
           display: flex;
           align-items: center;
           margin: 0 5px;
       }
    }
}
</style>
<style lang="less">
.com-date-time{
    .el-input--suffix .el-input__inner{
        padding: 0;
    }
    .times-elect .el-input__icon.el-icon-time{
        display: none;
    }
}
</style>