import checkBox from './check_box.vue'
import checkGroup from './check_box_group.vue'

checkBox.install = function(Vue){
    Vue.component(checkBox.name, checkBox);
}
checkGroup.install = function(Vue){
    Vue.component(checkGroup.name, checkGroup);
}

export {
    checkBox,
    checkGroup
};