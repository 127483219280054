<template>
    <div class="wk-table__wrapper" :class="tableData.page.show?'has-page':''">
        <div class="wk-table__table">
            <el-table class="table-table" :data="tableData.tBody" style="width: 100%" 
            highlight-current-row @current-change="handleCurrentChange"
            border @selection-change="handleSelectionChange" @row-click="clickRow" stripe v-if="tableType=='table'">
                <el-table-column
                v-if="checkbox"
                   type="selection"
                   width="55">
                </el-table-column>
                <template v-for="(item) in tableData.tHead">
                    <el-table-column :prop="item.prop" :show-overflow-tooltip="true" :width='item.width'
                                     :label="item.label" :key="item.prop" :column-key='item.prop'
                                     v-if="item.slot&&item.slot!=''&&item.show">
                        <template slot-scope="scope">
                            <slot :name="item.slot" :data='scope'></slot>
                        </template>
                    </el-table-column>
                    <el-table-column :prop="item.prop" :show-overflow-tooltip="true" :width='item.width'
                                     :label="item.label" :key="item.prop" :column-key='item.prop' v-else-if="item.show">
                    </el-table-column>
                </template>

            </el-table>
            <div class='list-table' v-if="tableType=='list'">
                <div v-for="(item, index) in tableData.tBody" class='list-item' :key="index">
                    <img :src="item[tableData.tHead.src]">
                    <!-- <el-image
                        style="width: 100px; height: 100px"
                        :src="item.imgs"
                        fit="cover"></el-image> -->
                    <div class='operate'>
                        <span class='edit btn' @click='table_edit(item)' v-if='editAuth'>
                            编辑
                        </span>
                        <span class='delete btn' @click='table_delete(item)' v-if='deleteAuth'>
                            删除
                        </span>
                    </div>
                    <div class='item-bot'>{{item[tableData.tHead.name]}}</div>
                </div>
            </div>
        </div>
        <div class="wk-table__page" v-if="tableData.page.show">
             <!-- @size-change="handleSizeChange" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize"  layout="total, prev, pager, next, jumper"-->
            <el-pagination
                @current-change="handleCurrentPageChange"
                :current-page.sync="tableData.page.currentPage"
                layout="total, prev, pager, next, jumper"
                :total="tableData.page.total">
            </el-pagination>
        </div>
    </div>

</template>
<script>
    export default {
        name: 'ContentTable',
        data: function () {
            return {
                style: null,
                pageSize: 10,
                currentPage: 1,
                config: ""
            };
        },
        props: {
            tableData: {
                type: Object,
                required: true,
            },
            checkbox: {
                type: Boolean,
                default:false,
            },
            tableType:{
                type:String,
                defalue:'table',
            },
            editAuth:{
                type: Boolean,
                default:true,
            },
            deleteAuth:{
                type: Boolean,
                default:true,
            }
        },
        created() {
            // console.log(this.tableData);
        },
        methods: {
            handleCurrentChange(data){
                this.$emit("current-change",data);
            },
            clickRow(row, column, event){
                this.$emit("row-click",row);
            },
            handleSizeChange(val){
                this.$emit("size-change", val);
            },
            handleCurrentPageChange: function (currentPage) {//页码切换
                console.log(currentPage,'currentPage')
                this.currentPage = currentPage;
                console.log(this.currentPage)
                this.$emit("page-change", currentPage);
            },
            handleSelectionChange(val){
                this.$emit('tableChange',val)
            },
            table_delete(data){
                this.$emit('tableDelete',data)
            },
            table_edit(data){
                this.$emit('tableEdit',data)
            },
        },
        beforeDestroy() {
            this.config = this.tableData.config
        },
        watch: {},
        mounted() {

        }
    }
</script>
<style lang="less">
    .wk-table__wrapper.has-page {
        .wk-table__table {
            height: calc(100% - 40px);
            .el-table {
                /*height: auto;*/
                .el-table__header-wrapper{
                    background: #f1f1f1;
                    th{
                       height: 35px;
                       padding:5px 0;
                        box-sizing: border-box;
                        background: #f1f1f1;
                        .cell{
                            // border-right: 1px solid #ddd;
                            font-size: 14px;
                        }
                        
                    }
                    th:last-child{
                        .cell{
                            border-right: 0;
                        }
                    }
                }
                .el-table__body-wrapper{
                    tr{
                        height: 40px;
                        // background: #f1f1f1;
                    }
                    tr.el-table__row--striped{
                        background: #fff;
                    }
                }
            }
        }
        .list-table{
            width:100%;
            overflow:hidden;
            .list-item{
                width:135px;
                height:180px;
                position:relative;
                float:left;
                margin-right:25px;
                margin-bottom:10px;
                border-radius: 5px;
                border: 1px solid #ccc;
                img{
                    display:inline-block;
                    width:100%;
                    height:100%;
                }
                .item-bot{
                    position:absolute;
                    bottom:0;
                    left:0;
                    width:100%;
                    height:30px;
                    background:rgba(0,0,0,0.5);
                    color:#fff;
                    text-align:center;
                    line-height:30px;
                }
                .operate{
                    position:absolute;
                    width:100%;
                    height:100%;
                    bottom:0;
                    left:0;
                    top:0;
                    margin:auto;
                    display:flex;
                    align-items:center;
                    padding:0 10px;
                    box-sizing:border-box;
                    justify-content:space-around;
                    span.btn{
                        display:none;
                        cursor:pointer;
                    }

                }
            }
            .list-item:hover{
                    .operate{
                        background:rgba(0,0,0,0.8);
                        span.btn{
                            display:block;
                        }
                    }
            }
        }
        .table-table{
            .table-btn{
                margin: 0 5px;
                cursor: pointer;
                background: #589EF8;
                padding: 8px 10px;
                border-radius: 6px;
                color: #fff;
            }
            .red{
                background: red;
            }
        }
        .wk-table__page{
            margin-top:20px;
            .el-pagination{
                display: flex;
            }
        }
    }
    .el-table--border, .el-table--group {
         border: none;
    }
    .wk-table__wrapper {
        width: 100%;
        height: 100%;
        .el-table {
            border: none;
        }
        .el-table::before {
            display: none;
        }
        .el-table th.is-leaf, .el-table td, .el-table tr, .el-table {
            // background: none;
        }
        .el-table th, .el-table td {
            padding: 0;
            text-align: center;
        }
        .el-table--border th, .el-table--border td {
            border-right: none;
        }
        .el-table td {
            color: #000;
            // border: none;
        }
        .el-table--enable-row-hover .el-table__body tr:hover > td {
            // background: none;
        }
        .el-table__column-resize-proxy, .el-table--group::after, .el-table--border::after {
            display: none;
        }
        // .el-table .cell {
        //     height: 40px !important;
        //     line-height: 40px;
        // }
        .el-button {
            padding: 0;
        }
        .el-table th.is-leaf {
            // border-bottom: 2px solid #3c546a;
        }
        // .el-pager li, .el-pagination .btn-prev, .el-pagination .btn-next {
        //     background: none;
        // }
        // .el-pagination, .el-pagination .btn-prev, .el-pagination .btn-next {
        //     // color: #24BBEE;
        // }
        // .el-pager li.active {
        //     padding: 0;
        //     min-width: 28px;
        //     color: #24BBEE;
        //     cursor: default;
        //     // text-shadow: #24BBEE;
        //     // background: radial-gradient(circle, rgba(0, 160, 233, 1), rgba(0, 160, 233, 0));
        //     border-radius: 50%;
        // }
        .table-operate {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            a,span {
                display: inline-block;
                margin: 0 3px;
                cursor: pointer;
            }
            .disabled{
                cursor: default;
                color:#ccc;
            }
            a:visited{
                color:#000;
            }
            a:link{
                color:#000;
            }
        }
    }
</style>


