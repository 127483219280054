<template>
    <componentDialog class="new-and-edit" :title="title" :dialogShow="show" :footerNo="true" @close="close"
       :class="planHeight ? 'planHeight' : ''">
    <el-scrollbar style="height:100%;width:100%">
        <div class="item" v-for=" (from_item,item) in formData" :key="item" >
            <comFrom nameWidth="150px" v-model="from_item.value" @comchange="data_change"
                :name="from_item.id" data-vv-delay="100" :errorsInfo="errors.first(from_item.id)"
                :data='from_item' v-validate="from_item.check_type"
                @from-input="select_change"
            > 
            <template slot="checkAll">
                <checkBox :name="'全选'" v-model="checkAll"></checkBox>
            </template>
            </comFrom>
        </div>
        <template name="footer">
            <font-button v-if="addShow" class="button" @click="addData">新增型号</font-button>
            <font-button class="button" @click="saveData">保存</font-button>
            <font-button class="button" @click="close">取消</font-button>
        </template>
    </el-scrollbar>
    </componentDialog>
</template>
<script>
export default {
    name:"newAndEdit",
    props:{
        formType:{
            type:String,
            default:"add" // add | edit
        },
        show:{
            type:Boolean,
            default: false,
        },
        formData:{
            type:Array,
            default(){
                return []
            }
        },
        title:{
            type:String,
            default:""
        },
        addShow:{
            type:Boolean,
            default:false
        },
        planHeight:{
            type:Boolean,
            default:false
        }
    },
    data(){
        return{
            checkAll: false
        }
    },
    methods:{
        close(){
            this.$emit("update:show", false)
        },
        data_change(data,value){
            this.$emit("dataChange", data, value)
        },
        qnchange(value){
            this.$emit("qnchange", value)
        },
        saveData(){
            this.$emit("saveData")
        },
        select_change(data){
            this.$emit("select_change",data)
        },
        addData(){
            this.$emit("addData")
        }
    },
    watch:{
        checkAll(newVal, odlVal){
            this.$emit("checkChange",newVal)
        }
    }
}
</script>
<style lang="less" scoped>
.new-and-edit{
    padding: 10px;
    .wk-dialog__body{
        height: 500px !important;
    }
    .item{
        margin: 10px;
    }
}
</style>
<style lang="less">
.planHeight{
    .wk-dialog__body{
        height: 650px !important;
    }
}
</style>