<template>
    <transition name="wk-dialog-fade">
        <div v-if="show" class="confirm">
            <div class="confirm-box bounceInDown">
                <div class="confirm-header" :class="exit?'':'header-bottom'">
                    <span class="confirm-header-title" v-html='title'></span>
                    <div class="confirm-close" @click="cancel" :class="exit?'confirm-close-big':''">×</div>
                </div>
                <div class="confirm-title">
                    <div v-if="title" v-html="info"></div>
                    <div class="confirm-content" v-if="info" v-html='content'></div>
                </div>
                <div class="confirm-box-buttons">
                    <font-button class="button" @click="success" v-if="submitText">{{submitText}}</font-button>
                    <font-button class="button" @click="cancel" type='cancel' v-if="cancelText">{{cancelText}}</font-button>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
export default {
    name:'componentDialog',
    data(){
        return{
            name:'componentDialog',
            show:true,
            isHide: false, //是否自动消失
            title:'消息提醒',
            info: "", //一级提醒
            content: "", //二层提醒
            submitText:'确定',
            cancelText:'取消',
            exit:false,
            success: function() {
               this.show =false;
              
            },
            cancel: function() {
               this.show = false;
               
            },
        }
    },
    created(){
      
    },
    methods:{
        close() {
            this.show = false; 
        }

    }
}
</script>
<style lang="less" scoped>
.confirm {
  position: fixed;
  z-index: 6999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  .confirm-box {
    width: 510px;
    height: auto;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    background:#fff;
    padding:22px;
    box-sizing:border-box;
    .confirm-header {
      width: 100%;
      height: 40px;
      position:relative;
      display: flex;
      align-items: center;
      justify-content:space-between;
      
    //   justify-content: space-between;
      .confirm-header-title {
          font-size: 16px;
          text-align:left;   
      }
      .confirm-close {
        cursor: pointer;
        font-size: 15px !important;
        width: 16px;
        height: 16px;
        text-align: center;
        line-height: 16px;
        background: transparent;
        transition: 0.2s;
        color: #000 !important;
      }
      .confirm-close:hover{
        transform: rotate(90deg)
      }
      .confirm-close-big{
        font-size: 30px !important;
        position: absolute;
        top:-5px;
        right:-5px;
      }
    }
    .header-bottom{
      border-bottom:1px solid #BFBFBF;
    }
  }
  .confirm-title {
    font-size: 16px;
    text-align: center;
    padding-top: 50px;
    color: #000 ;
  }
  .confirm-content {
    padding: 20px 0;
    line-height: 13px;
    font-size: 12px;
    text-align: center;
  }
  .confirm-box-buttons {
    width: 100%;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 40px;
    .button {
      margin: 0 5px;
    }
  }
}

.wk-dialog-fade-enter-active {
  -webkit-animation: dialog-fade-in 0.3s;
  animation: dialog-fade-in 0.3s;
}

.wk-dialog-fade-leave-active {
  -webkit-animation: dialog-fade-out 0.3s;
  animation: dialog-fade-out 0.3s;
}

@-webkit-keyframes bounceInDown {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0);
  }

  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }

  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes bounceInDown {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0);
  }

  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }

  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.bounceInDown {
  -webkit-animation-name: bounceInDown;
  animation-name: bounceInDown;
  animation: bounceInDown 0.8s 1;
}

@-webkit-keyframes dialog-fade-in {
  0% {
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes dialog-fade-in {
  0% {
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@-webkit-keyframes dialog-fade-out {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
    opacity: 0;
  }
}

@keyframes dialog-fade-out {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
    opacity: 0;
  }
}
</style>


