<template>
<componentDialog :width="width" class="info-detail" :title="title" :dialogShow="show" :footerNo="true" @close="close">
    <el-scrollbar style="height:100%;width:100%">
    <template v-if="type=='detail'">
        <div class="left-text" :class="(detailPicData.length || resultData.length)? 'width-half':''">
            <div class="detail-item" v-for="(item, index) in detailData" :key="index">
                <span class="item-name">{{item.name}}:</span>
                <span class="item-value">{{item.value}}</span>
            </div>
        </div>
        <div class="right-pic" v-if="detailPicData.length">
            <div class="detail-item" v-for="(item, index) in detailPicData" :key="index">
                <div class="item-name">{{item.name}}:</div>
                <div class="item-value">
                    <el-image style="width: 50px; height: 50px" :src="item.value" :fit="'fit'" :preview-src-list="[item.value]"></el-image>
                </div>
            </div>
        </div>
        <div class="right-result" v-if="resultData.length">
            <div class="title">成交结果</div>
            <div class="first indent-box" v-for="(item,key) in resultData" :key="key">
                <!-- 交易失败 -->
                <template v-if="item.status == 3">
                  经集采平台自动评定，贵单位提交的采购需求（
                  原材料型号为:
                  <span class="content-data">{{item.model}}</span>
                  采购价格:
                  <span class="content-data">{{item.price}}</span>,
                  采购数量:
                  <span class="content-data">{{item.purchaseNumber}}</span>
                  ，未能满足本次集采需求。
                </template>
                <!-- 交易成功 -->
                <template v-else>
                  经集采平台自动评定，为贵单位提供材料的供应商为：供应商编码
                  <span class="content-data">{{item.agencyCode}}</span>,
                  原材料型号为:
                  <span class="content-data">{{item.model}}</span>
                  报价为:
                  <span class="content-data">{{item.agencyPrice}}</span>,
                  提供总数量:
                  <span class="content-data">{{item.agencyNumber}}</span>
                </template>
            </div>
        </div>
    </template>
    <template v-if="type=='result'">
        <div class="result-box">
            <div class="div title indent">
            本次集中采购原材料为：
            <span>{{resultData.material}}</span>
            ，型号为
            <span>{{resultData.orderModel}}</span>。
            <template v-for="(item,key) in resultData.describe">
                <div :key="key">
                    <span :key="key">
                  <span>{{item.model}}</span>
                   需求提交总数量
                   <span>{{item.purchaseNumber}}</span>
                   吨，最终成交
                   <span>{{item.agencyNumber}}</span>
                   吨。
                </span>
                </div>
                
            </template>
            </div>
            <div class="div tips indent">
            系统分配结果如下：
            </div>
            <div class="div table">
                <ContentTable :tableData='tableData' tableType='table'> 
                    <template slot="operate" slot-scope="data">
                       <span 
                         style="lineHeight:50px;margin: 5px;cursor: pointer;color: #589EF8;padding: 8px 10px;border-radius: 6px;" 
                         v-if="data.data.row.status ==1" class="table-btn">待公布</span>
                        <template v-if="data.data.row.status ==2">
                           <span v-if="!data.data.row.contractStr"
                            style="lineHeight:50px;margin: 5px;cursor: pointer;color: #589EF8;padding: 8px 10px;border-radius: 6px;" 
                            class="table-btn my-pre">待反馈</span>
                            <span v-else @click="goPreview(data.data.row)"
                            style="lineHeight:50px;margin: 5px;cursor: pointer;color: #589EF8;padding: 8px 10px;border-radius: 6px;" 
                            class="table-btn my-pre">预览(完成交易)</span>
                        </template>
                        <span 
                          style="lineHeight:50px;margin: 5px;cursor: pointer;color: #589EF8;padding: 8px 10px;border-radius: 6px;" 
                          v-if="data.data.row.status ==3 || !data.data.row.status" class="table-btn">未成交</span>
                    </template>
                </ContentTable>
            </div>
        </div>
        <componentDialog :footerNo="true" class="img-detail" title="预览" :dialogShow="imgshow" @close="closeImg">
            <el-image  v-if="imageUrl" ref="myImg" :src="imageUrl" :preview-src-list="srcList"> </el-image>
        </componentDialog>
    </template>
    </el-scrollbar>
</componentDialog>
</template>
<script>
export default {
    name:"infoDetail",
    props:{
        type:{
            type: String, 
            default: "detail" // detail || result
        },
        width: {
            type: String,
            default: "550px"
        },
        show:{
            type:Boolean,
            default: false,
        },
        detailData:{
            type:Array,
            default(){
                return []
            }
        },
        detailPicData:{
            type:Array,
            default(){
                return []
            }
        },
        title:{
            type:String,
            default:""
        },
        resultData:{
            type:Object | Array,
            default(){
                return []
            }
        }
    },
    data(){
        return {
tableData:{
     tHead:[
        {
            prop:'agencyCode',
            label:'供应商编码',
            show:true,
            slot:'',
            width:'70px',
        },
        {
            prop:'purchaseCode',
            label:'采购方编码',
            show:true,
            slot:'',
            width:'70px',
          },
        {
          prop:'model',
          label:'型号',
          show:true,
          slot:'',
        },
        {
            prop:'purchaseNumber',
            label:'采购数量',
            show:true,
            slot:'',
            width:'60px',
        },
        {
            prop:'price',
            label:'期望价格',
            show:true,
            slot:'',
            width:'60px',
        },
        {
            prop:'priceInterval',
            label:'可接受价格浮动',
            show:true,
            slot:'',
            width:'80px',
        },
        {
            prop:'agencyNumber',
            label:'分配数量',
            show:true,
            slot:'',
            width:'60px',
        },
        {
            prop:'provideNumber',
            label:'提供数量',
            show:true,
            slot:'',
            width:'60px',
        },
        {
            prop:'agencyOffer',
            label:'单价',
            show:true,
            slot:'',
            width:'60px',
        },
        {
            prop:'status',
            label:'结果',
            show:true,
            width:"180px",
            slot:'operate',
        },
    ],
    tBody:[
        {agencyCode: 0}
    ],
    page:{
        show:false,
        total:0,
        pageSize:15,
    }
},
imageUrl:"",
srcList:[],
imgshow: false,
        }
    },
    created(){
        this.initResultData()
    },
    methods:{
        closeImg(){
            this.imgshow = false;
        },
        close(){
            this.$emit("update:show", false)
        },
        initResultData(){
            this.tableData.tBody = this.resultData.list
        },
        goPreview(data){
            const serverUrl =  "https://wrjc.wanrongly.com/"
            console.log(data)
            let urlStr = data.contractStr
            let urlArr = urlStr.split(",")
            let showArr = []
            urlArr.forEach(element => {
                element = serverUrl + element
                showArr.push(element)
            });
            this.imageUrl = showArr[0]
            this.imgshow = true;
            this.srcList = showArr
        }
    },
    watch:{
        resultData:{
            handler(newVal,old){
                console.log(newVal,old)
                this.initResultData()
            },
            deep:true
        }
    }
}
</script>
<style lang="less" scoped>
.info-detail{
    display: flex;
    .left-text{
        width: 100%;
        padding: 10px;
        margin-top: 10px;
        float: left;
        box-sizing: border-box;
    }
    .width-half{
        width: 50%;
        border-right: 1px solid #ccc;
    }
    .right-pic{
        width: 49%;
        float: right;
    }
    .detail-item{
        padding: 10px;
        text-align: left;
        .item-name{
            margin-right: 10px;
        }
    }
    .right-result{
        padding: 10px;
        width: 50%;
        height: 100%;
        text-align: left;
        line-height: 40px;
        .title{
            color: red;
            width: 100%;
            text-align: center;
        }
        .indent-box{
            text-indent: 20px;
        }
        .content-data{
            color: #2A74E3;
        }
    }
    .result-box{
        text-align: left;
        padding: 20px 0;
        box-sizing: border-box;
        line-height: 20px;
        max-height: 500px;
        .indent{
            text-indent: 20px;
            line-height: 30px;
        }
        .div{
            margin-top: 10px;
        }
        .title{

        }
    }
}
</style>
<style lang="less" >
.info-detail{
    .wk-dialog__body{
        display: flex;
        align-items: flex-start !important;
    }
}
.img-detail{
    .wk-dialog__body{
        height: 500px !important;
    }
    .el-image{
        // width: 100%;
        height: 100%;
        margin: 0 auto;
    }
}
</style>