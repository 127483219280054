<template>
<transition name='wk-dialog-fade'
    @after-enter="afterEnter">
    <div class="wk-dialog__wrapper" v-show="dialogShow" @click.self="handleWrapperClick">
        <div class="wk-dialog bounceInDown" :style="`height:${height};width:${width};top:${dialogTop}`">
            <div class="wk-dialog__header" :style="{marginLeft:marginLeft}">
                <slot name="title">
                    <span class="wk-dialog__title" >{{ title }}</span>
                </slot>
                <div class="wk-dialog__button" v-if="closeNo">
                    <slot name='button'></slot>
                </div>
                <div class="wk-dialog__close" @click="handleClose" v-else :style="`right:${right}`">
                   x
                </div>
            </div>

                <div class="wk-dialog__body">
                    <slot></slot>
                </div>
                <div v-if="footerNo"></div>
                <div class="wk-dialog__footer" v-else-if="$slots.footer">
                    <slot name='footer'></slot>
                </div>
                <div class="wk-dialog__footer" v-else>
                    <font-button class="button" @click="button_click('sure')">确定</font-button>
                    <!-- <font-button class="button" type='cancel' @click="button_click('cancel')">取消</font-button> -->
                </div>
        </div>
    </div>
</transition>
</template>
<script>
export default {
    name:'componentDialog',
     data: function () {
        return {
          style:null,
        };
    },
    props: {
        title: {
            type: String,
            default: "标题"
        },
        width: {
            type: String,
            default: "650px"
        },
        height: {
            type: String,
            default: "auto"
        },
        dialogShow: {
            type: Boolean,
            default: true
        },
        footerNo: {
            type: Boolean,
            default: false
        },
        closeNo: {
            type: Boolean,
            default: false
        },
        marginLeft:{            // 第二套样式需要的东西
            type: String,
            default: '0px'
        },
        right:{                // 第二套样式需要的东西
            type: String,
            default: '5px'
        },
        closeOnModal:{
            type: Boolean,
            default: true,
        },
        dialogTop:{
            type: String,
            default: '0px',
        },
    },
    methods: {
        handleClose() {
            this.hide();
            this.$emit('close')
        },
        handleWrapperClick() {
            if(this.closeOnModal){
                this.hide();
            }else{
                return;
            }
        },
        hide() {
            this.$emit("update:dialogShow", false);
            this.$emit("close");
        },
        button_click(type) {
            this.$emit("dialog-click", type);
            if (type === "cancel") {
                this.hide();
            }
        },
        afterEnter() {
            this.$emit('opened');
        },
    },
    beforeDestroy() {
    },
    watch: {}
}
</script>
<style lang="less" scoped>
.wk-dialog__wrapper {
    position: fixed;
    z-index: 999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.7);
    .wk-dialog {
        position: relative;
        max-height: 730px;
        overflow-y: hidden;
        border-radius: 5px !important;
        height: auto;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
        background:#fff;
        border:1px solid rgba(0,62,100,1);
        padding:22px;
        box-sizing:border-box;
        .wk-dialog__header {
            width: 100%;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-bottom:1px solid #BFBFBF;
            display:flex;
            justify-content:space-between;
            .wk-dialog__title {
                font-size: 16px;
                text-align:left;
            }
        }

        .wk-dialog__body {
            width: 100%;
            height: auto;
            align-items: center;
        }

        .wk-dialog__footer {
            width: 100%;
            height: 80px;
            display: flex;
            align-items: center;
            justify-content: center;
            .button {
                margin: 0 5px;
            }
        }

        .wk-dialog-scrollbar {
            height: calc(~"100% - 60px");
        }
    }
}

.img-list .img-upload i {
    margin-top: 0 !important;
}

.wk-dialog__close {
    cursor: pointer;

    font-size: 16px !important;
    // color: #24BBEE !important;
    width: 28px;
    height: 28px;
    text-align: center;
    line-height: 16px;
    background: transparent;
    transition: 0.2s;
    img{
        display:inline-block;
        width:100%;
        height:100%;
    }
}

.wk-dialog__close:hover {
    transform: rotate(90deg);
}
.wk-dialog__button{
    position:absolute;
    top:30%;
    font-size: 12px;
    .button-box{
        .button{
            color:#24BBEE;
            display: inline-block;
            cursor: pointer;
            margin-right: 10px;
        }

    }
    .button{
        color:#24BBEE;
        display: inline-block;
        cursor: pointer;
        margin-right: 10px;
    }
}
.wk-dialog-fade-enter-active {
    -webkit-animation: dialog-fade-in 0.3s;
    animation: dialog-fade-in 0.3s;
}

.wk-dialog-fade-leave-active {
    -webkit-animation: dialog-fade-out 0.3s;
    animation: dialog-fade-out 0.3s;
}

@-webkit-keyframes dialog-fade-in {
    0% {
        -webkit-transform: translate3d(0, -20px, 0);
        transform: translate3d(0, -20px, 0);
        opacity: 0;
    }

    100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@keyframes dialog-fade-in {
    0% {
        -webkit-transform: translate3d(0, -20px, 0);
        transform: translate3d(0, -20px, 0);
        opacity: 0;
    }

    100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@-webkit-keyframes dialog-fade-out {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translate3d(0, -20px, 0);
        transform: translate3d(0, -20px, 0);
        opacity: 0;
    }
}

@keyframes dialog-fade-out {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translate3d(0, -20px, 0);
        transform: translate3d(0, -20px, 0);
        opacity: 0;
    }
}

@-webkit-keyframes bounceInDown {

    from,
    60%,
    75%,
    90%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -3000px, 0);
        transform: translate3d(0, -3000px, 0);
    }

    60% {
        opacity: 1;
        -webkit-transform: translate3d(0, 25px, 0);
        transform: translate3d(0, 25px, 0);
    }

    75% {
        -webkit-transform: translate3d(0, -10px, 0);
        transform: translate3d(0, -10px, 0);
    }

    90% {
        -webkit-transform: translate3d(0, 5px, 0);
        transform: translate3d(0, 5px, 0);
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes bounceInDown {

    from,
    60%,
    75%,
    90%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -3000px, 0);
        transform: translate3d(0, -3000px, 0);
    }

    60% {
        opacity: 1;
        -webkit-transform: translate3d(0, 25px, 0);
        transform: translate3d(0, 25px, 0);
    }

    75% {
        -webkit-transform: translate3d(0, -10px, 0);
        transform: translate3d(0, -10px, 0);
    }

    90% {
        -webkit-transform: translate3d(0, 5px, 0);
        transform: translate3d(0, 5px, 0);
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.bounceInDown {
    -webkit-animation-name: bounceInDown;
    animation-name: bounceInDown;
    animation: bounceInDown 0.8s 1;
}
</style>


