import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 引入 form 校验规则
import './assets/js/validate.js';
//自定义全局方法库
import myFunction from '../src/assets/js/exportFunction'
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import myModules from './components/index.js';

//使用自定义组件库
Vue.use(myFunction);
Vue.use(ElementUI);
Vue.use(myModules)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
