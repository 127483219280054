<template>
    <span class="wk-checkBox">
        <el-checkbox 
            v-model="input_value" :disabled='disabled' 
            @change="change" :id="id" 
            :label='name' :name='name'
            >{{name}}</el-checkbox>
    </span>
</template>
<script>
export default {
    name:'checkBox',
    props:{
        value:'',
        name:String,
        disabled:Boolean,
        id:'',
        label:'',
    },
    data(){
        return{
            input_value:""
        }
    },
    created(){
        this.input_value = this.value
    },
    methods:{
        change(value){
            this.$emit('input',value)
        }   
    },
    watch:{
        value(newVal){
            this.input_value = newVal
        }
    }
}
</script>

<style lang="less" scoped>
.wk-checkBox{

}
</style>

