<template>
    <span class="wk-check-group" :class="data.expand.checkType=='circle'?'circle':''">
        <el-checkbox-group v-model="input_value" @change='handleChecked' :disabled='data.disabled||false'>
            <el-checkbox v-for="(item,index) in data.options"
                :key="item.id"
                :label='item.id'
                @change="checkOne($event,item,index)">{{item.name}}
            </el-checkbox>
        </el-checkbox-group>
    </span>
</template>
<script>
export default {
    name:'checkGroup',
    props:{
        data:{
            type: Object,
            default: function() {
              return {};
            }
        },
        value:{
            type:[Array,String],
            default(){
                return []
            }
        },
        type:{
            type:String,
            default:'circle'
        }
    },
    data(){
        return{
            input_value:[],
        }
    },
    created(){
        if(this.data.value.constructor == Array){
            this.input_value = this.data.value;
        }
        // this.input_value = this.data.value;
        // console.log(this.type,'this.type')
    },
    methods:{
        checkOne(value,item){
            this.$emit('checkOne',value,item)
        },
        handleChecked(val){
            console.log(val,this.input_value,'cecec')
            this.$emit('inout',this.input_value);
            this.$emit('change',this.input_value);
        }
    },
    watch:{
        value:function(){
            this.input_value =this.value
        },
        value(){

        },
        checkList:{
            handler(){

            },
            deep:true,
        }
    }
}
</script>
<style lang="less">
.wk-check-group{
    .el-checkbox-group{
        text-align: left;
    }
    .el-checkbox__label{
         
    }
    .is-checked{
        .el-checkbox__label{
           
        }
    }
}
.circle{
    .el-checkbox{
        .el-checkbox__input{
            border-radius: 50%;
            .el-checkbox__inner{
                border-radius: 50%;
                // position: relative;;
            }
            .el-checkbox__inner::after{
                // background: none;
            }
            
        }
    }
    .is-checked{
        .el-checkbox__inner{
            background: #fff;
            border:1px solid #DCDFE6;
        }
        .el-checkbox__inner::after{
                display: block;
                position: absolute;;
                top:0;
                left:0;
                right:-1px;
                bottom:-1px;
                margin:auto;
                border-radius: 50%;
                width: 4px;
                height: 4px;
                background: #4C4F82;
                color:#4C4F82;
        }
    }
    
}
</style>

