import comInput from './com_input.vue'
import comSelect from './com_select.vue'
import comFrom from './com_from.vue'
import comTextarea from './com_textarea.vue'
import comSelectRadio from './com_select_radio.vue'
import comDate from './com_date.vue'
import comImg from './com_img.vue'
import comDateTime from "./com_dateTime.vue"
comInput.install=function(Vue){
    Vue.component(comInput.name,comInput);
}
comSelect.install=function(Vue){
    Vue.component(comSelect.name,comSelect);
}
comFrom.install=function(Vue){
    Vue.component(comFrom.name,comFrom);
}

comTextarea.install=function(Vue){
    Vue.component(comTextarea.name,comTextarea);
}
comDate.install=function(Vue){
    Vue.component(comDate.name,comDate);
}

comSelectRadio.install=function(Vue){
    Vue.component(comSelectRadio.name,comSelectRadio);
}

comImg.install=function(Vue){
    Vue.component(comImg.name,comImg);
}

comDateTime.install=function(Vue){
    Vue.component(comDateTime.name,comDateTime);
}
export {
    comInput,
    comSelect,
    comFrom,
    comTextarea,
    comSelectRadio,
    comDate,
    comImg,
    comDateTime
}