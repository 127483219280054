import Axios from 'axios';
import Vue from 'vue';
const axios = Axios.create();
/*** 在请求数据之前的处理 */
export const axios_before_data = (type, data) => {
    if (type === 'get') {
        // let href = '';
        for (let index in data) {
            if(data[index]==='')delete data[index];
        }
        // console.log(href)
        return data;
    }else {
        for (let index in data) {
            if(data[index]==='')data[index]=null;
        }
        return data;
    }
}
let ajax_before = (type, data) => {
    axios.defaults.headers["tms-token"] = JSON.parse(sessionStorage.userInfo || "{}").token;
    return axios_before_data(type, data);

}

/*** 在数据请求回来之后的公共判断 */
let ajax_aftet = (result) => {
    return new Promise((resolve, reject) => {
        if (result.data && result.data.code == 200) {
            resolve(result.data);
        }else{
            Vue.prototype.$message.error(result.data.message)
            reject(result);
        }
    })
}
let ajax_aftet_export = (result) => {
    return new Promise((resolve, reject) => {
            resolve(result);
    })
}


/****
 * get 请求 
 */
export const ajax_get = (url, data) => {
    data = ajax_before('get', data);
    return axios.get(url, {
        params: data,
    }).then(result => {
        return ajax_aftet(result);
    })
}
export const ajax_get_auto = (url, data) => {
    data = ajax_before('get', data);
    return axios.get(url + '/' + data, {

    }).then(result => {
        return ajax_aftet(result);
    })
}
export const ajax_get_form = (url, data) => {
    data = ajax_before('get', data);
    let params = "";
    for(let k in data){
        params+= "&"+k+"="+data[k];
    }
    return axios.get(url+'?'+params)
        .then(result => {
            return ajax_aftet(result);
        });
}
export const ajax_get_export = (url, data) => {
    data = ajax_before('get', data);
    return axios.get(url, {
        params: data,
    }).then(result => {
        return ajax_aftet_export(result);
    })
}

/****
 * post 请求 
 */
export const ajax_post = (url, data ) => {
    data = ajax_before('post', data );
    return axios.post(url,data
       ).then(result => {
        return ajax_aftet(result);
    })
}
/**
 * delete 请求   用于删除
 */
export const ajax_delete = (url, data) => {
    data = ajax_before('delete', data);
    return axios.request({
        url: url,
        method: 'delete',
        params: data,
        
    }).then(result => {
        return ajax_aftet(result);
    })
}

/**
 * delete 请求   用于删除
 */
export const ajax_delete_auto = (url, data) => {
    data = ajax_before('delete', data);
    return axios.request({
        url: url + '/' + data,
        method: 'delete',
        params: data,
        
    }).then(result => {
        return ajax_aftet(result);
    })
}
/**
 * put 请求    用于修改
 */
export const ajax_put = (url, data) => {
    data = ajax_before('put', data);
    return axios.request({
        url: url,
        method: 'put',
        // params: data.params,
        data: data,
    }).then(result => {
        return ajax_aftet(result);
    })
}
/**
 * put 请求    用于修改
 */
export const ajax_put_auto = (url, data) => {
    data = ajax_before('put', data);
    return axios.request({
        url: url + '/' + data.params + '/' ,
        method: 'put',
        // params: data.params,
        data: data.data,
    }).then(result => {
        return ajax_aftet(result);
    })
}

/****
 * post params请求 
 */
export const ajax_post_params = (url, data ) => {
    data = ajax_before('post', data);
    return axios.request({
        url: url,
        method: 'post',
        params: data,
    }).then(result => {
        return ajax_aftet(result);
    })
}
/****
 * post params_body请求 
 */
export const ajax_post_params_body = (url, data ) => {
    data = ajax_before('post', data);
    return axios.request({
        url: url,
        method: 'post',
        params: data.params,
        data:data.data
    }).then(result => {
        return ajax_aftet(result);
    })
}
/****
 * file 文件服务 
 */
export const oss_config_get = ()=>{
    return new Promise((resolve, reject) => {
        ajax_get('/api/oss/config').then(res=>{
            if(res.code == '1'){
                resolve(res.data);
            }
        }).catch(err=>{
            console.log('ajax_err:',err)
            reject(err)
        })

    })
}
// oss_config_get();  上传文件
export const  file_update = async (event,path)=>{
    var data = new FormData();
    let lastIndex = event.target.files[0].name.lastIndexOf(".");
    let file_type = event.target.files[0].name.substring(lastIndex + 1);
    let key = path +  event.target.files[0].name;
    data.append('file',event.target.files[0])
    data.append('key',key)
    let updata = await oss_config_get();
    for(let i in updata){
        data.append(i,updata[i])
    }
    return new Promise((resolve, reject) => {
        ajax_post('/api/common/upfile',data).then(res=>{
            resolve (res);
        }).catch(err=>{
            console.log('ajax_err:',err)
            reject(err)
        })

    })
    
}
export const  file_delete = async (file)=>{
    return new Promise((resolve, reject) => {
        ajax_get('/api//upload/deleteFile',file).then(res=>{
            resolve (res);
        }).catch(err=>{
            console.log('ajax_err:',err)
            reject(err)
        })

    })
    
}

export const  postByFormUrl = (url,data)=>{
    return axios.post(url, data, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
        },
        transformRequest: [function (data) {
            let ret = '';
            for (let item in data) {
                // 这些字段不需要提交
                if (item === 'creator' || item === 'created' || item === 'lastModifier' || item === 'lastModified') {
                    continue;
                }
                if (data[item] === 0 || data[item] === '' || data[item] === false) {
                    ret += encodeURIComponent(item) + '=' + encodeURIComponent(data[item]) + '&'
                } else if (data[item]) {
                    ret += encodeURIComponent(item) + '=' + encodeURIComponent(data[item]) + '&'
                }
            }
            return ret.slice(0, ret.length - 1);
        }],
    })
}
