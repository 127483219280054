<template>
    <el-radio-group  v-model="input_value" :disabled="data.disabled||false" @change="radiochange">
        <el-radio 
        v-for="(item,index) in data.options" 
        :label="item.id" 
        :key="index">{{item.name}}</el-radio>
    </el-radio-group>
</template>

<script>
export default {
  name: "comSelectRadio",
  props: {
    data: {
      type: Object,
      default: function() {
        return {};
      }
    },
    value: {
      type: [String,Number],
      default: ""
    }
  },

  data: function() {
    return {
       input_value:this.value
    };
  },
  computed: {

  },
  mounted(){},
  methods: {
    radiochange(){  
      this.$emit("input", this.input_value);
          this.$emit('change',this.input_value);
          this.$emit('click',this.input_value)
      }
  },
  watch: {
    value:function(){
      this.input_value =this.value
    },
    "data.options.length"(){
      this.data.options.reverse(); 
    }
  }
};
</script>
<style lang="less">

.el-radio{
        //  color: rgba(36,187,238,.7);    
}
.is-checked{
    .el-radio{
        // color:#24BBEE;
        // color:#000;
        // color:#000;
    }
}
.el-radio__inner{
    // border: 1px solid rgba(0,62,100,1);
    // background: rgba(0,62,100,0.5);
}
.el-radio__input.is-checked + .el-radio__label{
    // color:#24BBEE;
    // color:#000;
}
.el-checkbox__input.is-checked+.el-checkbox__label{
    color:#000;
}
.el-radio__input.is-checked .el-radio__inner{
    // border-color: teal;
    // background: radial-gradient(circle,rgba(0,255,0,1),rgba(0,255,0,0));;
}
.el-radio__input.is-disabled .el-radio__inner{
  //  background: rgba(0,62,100,0.5);
  //  border: 1px solid rgba(0,62,100,1);
}
.el-radio__input.is-disabled.is-checked .el-radio__inner{
  //  background: radial-gradient(circle,rgba(0,255,0,1),rgba(0,255,0,0));
   border:0;
}
</style>

<style scoped lang="less">

</style>