
import Vue from 'vue';

import ContentTable from './table/table.js';
import { comInput, comSelect, comFrom, comTextarea, comSelectRadio ,comDate , comImg, comDateTime} from './from/from.js'
import componentDialog from './dialog/dialog'
import ConfirmDialog from './confirm/confirm.js'
import fontButton from './fontButton/fontButton'
import comBreadcrumb from './breadcrumb/comBreadcrumb'
import {checkBox,  checkGroup, } from './checkBox/check_box.js'
import statisticBar from "./statisticBar/statisticbar"
import infoDetail from './infoDetail/infoDetail'
import newAndEdit from './newAndEdit/newAndEdit'
const components = [
    ContentTable,
    componentDialog,
    comInput,
    comSelect,
    comFrom,
    comTextarea,
    comSelectRadio,
    comDate, 
    comImg, 
    comDateTime,
    fontButton,
    comBreadcrumb,
    statisticBar,
    infoDetail,
    newAndEdit,
    checkBox,
    checkGroup,
]
const install = function(Vue) {
    components.map(component => {
        Vue.component(component.name, component);
    });
    Vue.prototype.$Confirm = ConfirmDialog;
}

install(Vue);

export default {
    ContentTable,
    componentDialog,
    comInput,
    comSelect,
    comFrom,
    comTextarea,
    comSelectRadio,
    comDate, 
    comImg, 
    comDateTime,
    fontButton,
    comBreadcrumb,
    statisticBar,
    infoDetail,
    newAndEdit,
    checkBox,
    checkGroup,
}