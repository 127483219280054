import axios from '../assets/js/axios';
import {ajax_get, ajax_get_auto, ajax_post, export_file, ajax_put, ajax_delete, ajax_put_auto, ajax_delete_auto, ajax_post_params,} from "./common_api";

//请求根路径
const base ='/api';

/**
 * 登录
 * @param params "loginName": "","password": ""
 * @returns {*}
 */
// 登录接口
export const login = data => ajax_post(`${base}/admin/login`,data);
// 退出接口
export const exit = data => ajax_get(`${base}/users/logout`,data);

/**
 * 企业管理相关接口
 * 
 */
// 获取企业列表
export const ajax_get_companyList = data => ajax_get(`${base}/company/list`,data);
// 修改状态
export const ajax_post_companyUpdate = data => ajax_post(`${base}/company/updateStatus`,data);
// 更新企业信誉度
export const ajax_post_companyUpdateCore = data => ajax_post(`${base}/company/updateCreditScore`,data);

/**
 * 材料 型号 角色接口
 * 
 */
 export const ajax_get_enumsMaterial = data => ajax_get(`${base}/model/materialList`,data);
 export const ajax_get_enumsModel = data => ajax_get(`${base}/model/list`,data);
 export const ajax_get_enumsRole = data => ajax_get(`${base}/role/list`,data);
 export const ajax_get_statisticData = data => ajax_get(`${base}/company/companyCount`,data);
/**
 * 采购计划相关接口
 * 
 ****************************************
 采购计划
 ****************************************
*/
// 获取计划列表
export const ajax_get_planList = data => ajax_get(`${base}/purchaseOrder/pageList`,data);
// 计划新增
export const ajax_post_planAdd = data => ajax_post(`${base}/purchaseOrder/insert`,data);
// 计划修过
export const ajax_post_planEdit = data => ajax_post(`${base}/purchaseOrder/update`,data);
// 启用 禁用 启用 禁用
export const ajax_post_planStatus = data => ajax_get(`${base}/purchaseOrder/updateStatus`,data);
// 查看计划结果
export const ajax_get_planresult = data => ajax_get(`${base}/purchaseOrderDetail/list`,data);

//****************************************
//自动采购计划
//****************************************
// 获取自动采购计划列表
export const ajax_get_autoPlanList = data => ajax_get(`${base}/purchaseOrderAuto/pageList`,data);
// 自动采购计划新增
export const ajax_post_autoPlanAdd = data => ajax_post(`${base}/purchaseOrderAuto/insert`,data);
// 自动采购计划修过
export const ajax_post_autoPlanEdit = data => ajax_post(`${base}/purchaseOrderAuto/update`,data);
// 自动采购计划删除 启用 禁用
export const ajax_get_autoPlanStatus = data => ajax_get(`${base}/purchaseOrderAuto/updateStatus`,data);
// 信誉度获取
export const ajax_get_getCore = data => ajax_get(`${base}/creditpoints/getDetail`,data);
// 信誉度设置
export const ajax_post_setCore = data => ajax_post(`${base}/creditpoints/update`,data);


/**
 * 
 * 过程管理相关接口
 *
 */
// 需求列表
export const ajax_get_demandList = data => ajax_get(`${base}/purchaseOrderDetail/pageList`,data);
// 报价列表
export const ajax_get_quoteList = data => ajax_get(`${base}/agencyOffer/pageList`,data);


/**
 * 用户管理相关接口
 * 
 */
// 获取用户列表
export const ajax_get_userList = data => ajax_get(`${base}/admin/pageList`,data);
//修改用户信息
export const ajax_post_userEdit = data => ajax_post(`${base}/admin/update`,data);
// 新增用户
export const ajax_post_userAdd = data => ajax_post(`${base}/admin/insert`,data);
// 修改密码
export const ajax_post_userPass = data => ajax_post(`${base}/admin/updatePassword`,data);
// 删除用户
export const ajax_get_userDelete = data => ajax_get(`${base}/admin/delete`,data);
// 获取角色列表
export const ajax_get_roleList = data => ajax_get(`${base}/role/pageList`,data);
//修改角色信息  设置角色权限
export const ajax_post_roleEdit = data => ajax_post(`${base}/role/update`,data);
// 新增角色
export const ajax_post_roleAdd = data => ajax_post(`${base}/role/insert`,data);

// 内容管理

// 集中采购须知 新增 修改
export const ajax_post_xuzhi = data => ajax_post(`${base}/configuration/update`,data);
// 查询
export const ajax_get_xuzhi = data => ajax_get(`${base}/configuration/getDetail`,data);
// 评价列表
export const ajax_get_evaluation = data => ajax_get(`${base}/advice/pageList`,data);
// 材料型号列表
export const ajax_get_materialAndmodelList = data => ajax_get(`${base}/model/materialPageList`,data);
// 新增材料型号
export const ajax_post_materialAndmodelAdd = data => ajax_post(`${base}/model/insert`,data);
// 修改材料型号
export const ajax_post_materialAndmodelUpdate = data => ajax_post(`${base}/model/update`,data);

// banner 维护
// 列表
export const ajax_get_bannerList = data => ajax_get(`${base}/materialPrice/getBannerList`,data);
// 新增
export const ajax_post_bannerAdd = data => ajax_post(`${base}/materialPrice/insertBanner`,data);
// 删除
export const ajax_get_bannerDelete = data => ajax_get(`${base}/materialPrice/deleteBanner`,data);

// 文件上传
export const fileUpload = data => ajax_post(`${base}/upload/imageUpload`,data);
