<template>
    <div class="statistic-bar">
        <span class="statistic-item" v-for="(item, index) in statisticData" :key="index">
            <span>{{item.name}}</span>
            <span>({{item.value}})</span>
        </span>
    </div>
</template>
<script>
export default {
    name:"statisticBar",
    props:{
        statisticData:{
            type: Array,
            value: []
        }
    },
    data(){
        return {}
    }
}
</script>
<style lang="less" scoped>
.statistic-bar{
    text-align: left;
    font-size: 14px;
    background: #f1f1f1;
    border-bottom: 1px solid #bdc0c7;
    .statistic-item{
        padding: 10px 15px;
        line-height: 40px;
    }
}
</style>