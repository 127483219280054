<template>
<span class="com-select">
    <el-select
    v-model="input_value"
    :placeholder="data.placeholder||'请选择'"
    :disabled='data.disabled'
    :class="data.unit&&data.unit!=''?'w':''"
    @change='select_change'
    :clearable='data.clearable||false'
    :name="data.id"
    @visible-change='select_visible'
    :popper-class='popper_class'
    filterable
    :multiple="(data.expand&&data.expand.multiple==true)||false"
    :allowCreate="(data.expand&&data.expand.allow_input==true)||false"
    >
    <el-option v-for="(item,index) in data.options"
        :key="index"
        :label="item[params.label]"
        :value="item[params.value]"></el-option>
    </el-select>
    <div id="dw" v-if="data.unit&&data.unit!=''" class="el-input__icon com-input-unit" :class="data.unit&&data.unit!=''?'dw_pd':''">{{data.unit}}</div>

</span>
</template>

<script>
export default {
    name: "comSelect",
    props: {
        data: {
            type: Object,
            default: function () {
                return {};
            }
        },
        params:{
            type:Object,
            default(){
                return{
                    label:"label",
                    value:"value",
                }
            }
        },
        value: {
            default: ""
        },
        visibleClear:{
            type:Boolean,
            default:false,
        }
    },
    data: function () {
        return {
            input_value: "",
            popper_class:sessionStorage.jbf_cloud_sysType + '-popper-class',
        };
    },
    created() {
        this.input_value = this.value;
    },
    mounted(){},
    computed: {
        disabled: function () {
            let edit_flag = this.$route && this.$route.query && this.$route.query.type && this.$route.query.type === "edit";
            if (edit_flag) {
                return this.data.disabled || false;
            } else {
                return this.data.new_disabled || false;
            }
        }
    },
    methods: {
        select_change(data) {
            let obj = {};
            obj.value = data;
            obj.id = this.data.id;
            let option_length = this.data.options.length;
            for (let index = 0; index < option_length; index++) {
                // if (data === this.data.options[index].id) {
                if (data === this.data.options[index][this.params.value]) {
                    obj.name = this.data.options[index].name;
                    obj.sourceInfo = this.data.options[index].sourceInfo || "";
                    break;
                }
            }
            this.$emit("select-option", obj);
            this.$emit("input", data);
        },
        select_visible(val){
            if(this.visibleClear && val){
                this.input_value = ''
                this.$emit("input", '');
            }
        },
    },
    watch: {
        value: function () {
            if (parseInt(this.value)) {
                // this.input_value = parseInt(this.value);
                this.input_value = this.value;
            } else {
                this.input_value = this.value;
            }
        },
        data:{
            
            handler(n){
                // console.log(1)
                // console.log(n)
            },
            deep:true,
        }
    }
};
</script>

<style lang="less">
.com-select {
    position: relative;
    display: inline-block;
    width: 100%;
    // height: 22px;
    height: 34px;
    .el-select {
        width: 100%;
        height: 100%;
        min-height: 34px;
        .el-input {
            width: 100%;
            height: 100%;
            min-height: 34px;
            input {
                height: 100%;
                border-radius: 0;
                padding: 0 5px;
                line-height: 1;
                // border:1px solid rgba(0,62,100,1);
                // background: rgba(0,62,100,0.5);
                background: none;
                min-height: 34px;
                // color:#24BBEE;
            }

            input[disabled="disabled"] {
                color: #ccc;
            }
        }
    }
    .el-input__suffix{
        right:-3px;
    }
    .w {
        .el-input {
            input {
                padding-right: 12%;
            }
        }
    }

    .el-select .el-input__inner:focus {
        // border-color: #108cee;
    }

    .el-input.is-active .el-input__inner,
    .el-input__inner:focus {
        // border-color: #108cee;
    }
    #dw {
        width: 50px;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto 0;
        right: 25px;
    }
    .el-input__icon {
        height: 100%;
        width: 25px;
        text-align: center;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
        line-height: 35px;
    }

    .com-input-unit {
        font-size: 14px;
        width: auto;
        line-height: 34px;
        text-align: right;
        color: #c0c4cc;
    }

    .el-input {
        font-size: 12px;
    }

    .title {
        position: absolute;
        right: -99px;
        width: 89px;
        text-decoration: underline;
        top: 0px;
        line-height: 34px;
        cursor: pointer;
    }
    .el-input__icon{
        line-height: 22px;
    }
    
}
</style>
