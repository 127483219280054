<template>
    <span class="qn-date" >
        <el-date-picker
                :editable="false"
                :clearable="false"
                @change="change1"
                v-model="input_value"
                :popper-class='popper_class'
                value-format="yyyy-MM-dd HH:mm:ss"
                :type="type"
        ></el-date-picker>
    </span>
</template>

<script>
    export default {
        name: "comDate",
        data() {
            return {
                input_value: '',
                popper_class:sessionStorage.jbf_cloud_sysType + '-popper-class',
            }
        },
        props: {
            data: {
                type: Object,
                default: function () {
                    return {
                        // startPlaceholder:"201904021000"
                    }
                }
            },
            value: {
                default: ""
            },
            type:{
                default: ""
            }
        },
        created() {
            this.input_value = this.value;
        },
        computed: {
            currentValue: function () {
                return this.value
            }
        },
        methods: {
            change1() {
                this.$emit("input", this.input_value)
            }
        },
        watch:{
            input_value: function() {
                this.$emit('input', this.input_value);
            },
            'value':function(){
                this.input_value=this.value;              
            }
        }
    }
</script>

<style lang="less">
    .qn-date {
        position: relative;
        display: inline-block;
        width: 100%;
        // height: 22px;
        height: 34px;
        background: none;
        .el-date-editor.el-input{
            width: 100%;
            height: 100%;
            background: none;
        }
        .el-input__inner {
            // height: 20px !important;
            // line-height: 20px !important;
            height: 34px !important;
            line-height: 34px !important;
            background: none;
            color: #24BBEE;
            color: #000;
            // border:1px solid rgba(0,62,100,1);
            width: 100%;
        }
        .el-input__icon{
            // line-height: 20px;
            line-height: 34px;
        }
        .el-input--suffix .el-input__inner {
            padding-right: 10px;
        }
    }
</style>