"use strict";
import Vue from 'vue';
import axios from "axios";
import {Loading} from 'element-ui';
const noToken = ["/api/security/jwt/token","/api/ops/fileType/list","/api/ops/download/list","/api/fireunit/fireman/export", "/api/fireunit/fireequip/export"];
const noLoading =["/api/fireunit/import/list","/api/fireunit/place/children", "/api/fireunit/layout/change/scale"];
// const noLoading =["/api/fireunit/import/list","/api/fireunit/layout/add","/api/fireunit/layout/next","/api/fireunit/place/children",
//   "/api/fireunit/layout/change/scale"];
let loadingInstance = '';
const options = {
    fullscreen: true,
    background: "rgba(255,255,255,.4)",
    text: 'Loading',//显示在加载图标下方的加载文案
    spinner: "",//自定义加载图标类名
    customClass: ''//Loading 的自定义类名
}
let config = {
    // baseURL: process.env.baseURL || process.env.apiUrl || ""
    // timeout: 60 * 1000, // Timeout
    // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);
// POST application/x-www-form-urlencoded
_axios.postByFormUrl = function (url, data) {
    return _axios.post(url, data, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
        },
        transformRequest: [function (data) {
            let ret = '';
            for (let item in data) {
                ret += encodeURIComponent(item) + '=' + encodeURIComponent(data[item]) + '&'
            }
            return ret.slice(0, ret.length - 1);
        }],
    })
};
_axios.getByFormUrl = function (url, data) {
    return _axios.get(url, data, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
        },
        transformRequest: [function (data) {
            let ret = '';
            for (let item in data) {
                ret += encodeURIComponent(item) + '=' + encodeURIComponent(data[item]) + '&'
            }
            return ret.slice(0, ret.length - 1);
        }],
    })
};
// PUT multipart/form-data
_axios.postByFormData = function (url, data) {
    return _axios.post(url, data, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        transformRequest: [function (data) {
            let ret = new FormData();
            for (let item in data) {
                ret.append(item, data[item]);
            }
            return ret;
        }],
    })
};
// PUT multipart/form-data
_axios.getByFormData = function (url, data) {
    return _axios.get(url, data, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        transformRequest: [function (data) {
            let ret = new FormData();
            for (let item in data) {
                ret.append(item, data[item]);
            }
            return ret;
        }],
    })
};
// request拦截器
_axios.interceptors.request.use(config => {
    if(noLoading.findIndex(v=>v==config.url) == -1){
        loadingInstance = Loading.service(options);
    }
    if(noToken.findIndex(v=>v==config.url) == -1){
        if (sessionStorage.getItem('jbf_cloud_token')) {
            config.headers.common['access-token'] = sessionStorage.getItem('jbf_cloud_token')
        }
        // else{
        //     Vue.prototype.$message.error("会话已失效，请重新登录");
        //     Vue.router.replace('/login');
        // }
    }
    // Do something before request is sent
    return config
}, error => {
    // Do something with request error
    console.log(error) // for debug
    if(noLoading.findIndex(v=>v==config.url) == -1){
        loadingInstance.close();
    }
    return Promise.reject(error);
})


// Add a response interceptor
_axios.interceptors.response.use((response)=>{
        if(noLoading.findIndex(v=>v==response.config.url) == -1){
            loadingInstance.close();
        }
        // Do something with response data
        if(!response.data.code){
            return response.data
        }else if(response.data.code&&response.data.code == 908){
            if(response.config.url == "/api/fireunit/fireUnit/allPCD" || response.config.url == "/api/fireunit/fireUnit/allFSU"
                || response.config.url == "/api/ops/checkRule/getCheckRule"){
                console.warn(JSON.stringify(response.data));
            }
            else{
                console.warn(JSON.stringify(response.data));
                Vue.prototype.$message.error(response.data.message);
                Vue.router.replace('/login');
            }
        }else if (response.data.code&&response.data.code !== 200) {
            console.warn(JSON.stringify(response.data));
            Vue.prototype.$message.error(response.data.message);
            return Promise.reject(response)
        } else {
            return response.data
        }
    },
    function (error) {
        if(noLoading.findIndex(v=>v==error.config.url) == -1){
            loadingInstance.close();
        }
        // Do something with response error
        return Promise.reject(error);
    }
);


export default _axios;
