<template>
<div class="com-textarea">
        <el-input 
        v-model="input_value" 
        type="textarea"
        :rows="5"
        resize="none"
        :placeholder="data.placeholder||''" 
        :name='data.name||""' 
        :disabled="disabled"
        >
        </el-input>
        <div v-if="data.unit&&data.unit!=''" class="el-input__icon com-input-unit">{{unitnum}}/{{data.unit}}</div>
</div>
</template>

<script>
export default {
    name: 'comTextarea',
    props:['value','data'],
    props: {
        data:{
            type:Object,
            default:function(){
                return {};
            }
        },
        value:{
            default:'',
        },
    },

    data: function() {
        return {
            input_value:'',
        }
    },
    created () {
        this.input_value=this.value; 
        
        
    },
    mounted(){
      
    },
    methods: {},
    computed: {
        disabled: function() {         
            let edit_flag =this.$route&&this.$route.query&&this.$route.query.type && this.$route.query.type === 'edit';
            if (edit_flag) {
                return this.data.disabled || false;
            } 
            else
            {
                return this.data.new_disabled || false;
            }
        },
        unitnum(){
            return this.input_value.length;
        }
    },
    watch: {
        input_value: function() {
            this.$emit('input', this.input_value);
        },
        'value':function(){
            this.input_value=this.value;              
        }
    }
}
</script>

<style lang='less'>
.com-textarea{
    width: 100%;
    // height: 120px;
    min-height: 50px;
    display: inline-block;
    position: relative;
    .el-input {
        height: 100%;
        font-size: 12px;
        input {
            height: 100%;
            border-radius: 0;
            padding: 0 5px;
            background: none;
        }
    }
    .el-input--suffix{
        input{
            padding-right: 30px;
        }
    }
    .el-textarea{
        font-size: 12px;
        textarea{
            background: none;
            border:1px solid rgba(0,62,100,1);
            color:#24BBEE;
        }
    }
    .el-textarea__inner{
        // border:
    }
    .com-input-unit{
        line-height: 18px;
        width:  auto !important;
        height: 18px;
        text-align: right;
        position: absolute;
        right: 5px;
        color: rgb(153,153,153);
        bottom: 5px;
    }
    .el-select .el-input__inner:focus {
        border-color: #108CEE;
    }
    .el-input.is-active .el-input__inner,
    .el-input__inner:focus {
        border-color: #108CEE;
    }
    .el-textarea__inner{
        padding: 5px 5px;
        border-radius: 0 !important; 
    }
}
</style>