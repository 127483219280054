import Vue from 'vue'
import ConfirmDialogComponent from './confirm.vue'
//合并对象函数,这个方法是会改变,第一个参数的值的
const Confirm_dialog = function(target) {
    console.log(target,'targettargettargettarget')
    console.log(arguments,'argumentsargumentsargumentsarguments')
    for (let i = 1, j = arguments.length; i < j; i++) {
        let source = arguments[i] || {};
        for (let prop in source) {
            if (source.hasOwnProperty(prop)) {
                let value = source[prop];
                if (value !== undefined) {
                    target[prop] = value;
                }
            }
        }
    }
    return target;
};
//extend 是构造一个组件的语法器.传入参数,返回一个组件
let ConfirmDialogConstructor = Vue.extend(ConfirmDialogComponent);
let initInstance = () => {
    //实例化ConfirmConstructor组件
    let instance = new ConfirmDialogConstructor({
        el: document.createElement('div')
    });
    //添加到boby
    document.body.appendChild(instance.$el);
    let xh = setTimeout(function () {
        if (instance.isHide) {
            instance.show = false;
            this.close();
        }
        clearTimeout(xh);
    }, 3000);

    return instance;
}
let ConfirmDialog = (options = {}) => {
    //初始化
   let _instance= initInstance();
   console.log(_instance,'_instance_instance')
    // 将单个 confirm instance 的配置合并到默认值(instance.$data,就是main.vue里面的data)中
    // Confirm_dialog(_instance.$data,options);
    Confirm_dialog(_instance._data,options);
    //返回Promise
    return new Promise((resolve, reject) => {
        _instance.success = () => {
            if(_instance.$el.parentNode)
            _instance.$el.parentNode.removeChild(_instance.$el);
            _instance.show = false;
            //再执行自定义函数
            resolve(_instance.data);
        }
        _instance.cancel = () => {
            if(_instance.$el.parentNode)
            _instance.$el.parentNode.removeChild(_instance.$el);
            _instance.show = false;
            reject();
        }
    });
};


export default ConfirmDialog;
