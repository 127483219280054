<template>
    <button v-if="type==='sure'" class="qn-font-button-sure" :class="{'qn-font-button-disabled-sure':disabled}" :disabled='disabled' :style="`width:${width};height:${height};cursor:${cursor};fontSize:${fontsize};`" @click.stop="btn_click()">
       <slot></slot>
    </button>
    <button v-else class="qn-font-button-cancel" :class="{'qn-font-button-disabled-cancel':disabled}" :disabled='disabled' :style="`width:${width};height:${height};fontSize:${fontsize};`" @click.stop="btn_click()">
       <slot></slot>
    </button>
</template>
<script>
export default {
  name: 'fontButton',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    width: {
      //宽度
      type: String,
      default: '80px'
    },
    height: {
      //高度
      type: String,
      default: '30px'
    },
    fontsize: {
      //高度
      type: String,
      default: '14px'
    },
    type: {
      type: String,
      default: 'sure'   //'sure'  确定按钮   'cancel'  取消按钮
    },
    cursor: {
      type: String,
      default: ''
    }

  },
  computed: {

  },
  methods: {
    btn_click(evt) {
      this.$emit('click');
    },
  }
};
</script>

<style lang="less" scoped>
.qn-font-button-sure {
  width: 80px;
  height: 30px;
  margin-right: 10px;
  // margin-bottom: 20px;
  
  
  text-align: center;
  vertical-align: middle;
  font-size: 14px;
  color: #000;
  outline: none;
  cursor: pointer ;
  background:rgba(242,242,242,1);
  border:none;
  
}

// .qn-font-button-sure:hover {
//   background-image: linear-gradient(180deg, #2bace0 0%, #34a0f9 100%);
// }

// .qn-font-button-sure:active {
//   background-image: linear-gradient(180deg, #2abbf5 0%, #0f8ced 100%);
// }

.qn-font-button-cancel {
  width: 80px;
  height: 30px;
  // margin-bottom: 20px;
  text-align: center;
  vertical-align: middle;
  font-size: 14px;
  color: #000;
  font-weight: normal;
  font-stretch: normal;
  line-height: 0px;
  letter-spacing: 0px;
  outline: none;
  cursor: pointer ;
  background:rgba(242,242,242,1);
  border:none;
}

// .qn-font-button-cancel:hover {
//   background-color: #f7f7f7;
//   border: solid 1px #d7d7d7;
// }

// .qn-font-button-cancel:active {
//   background-color: #fff;
// }

// .qn-font-button-disabled-cancel,
// .qn-font-button-disabled-cancel:hover {
//   cursor: not-allowed;
//   background: #fff !important;
// }

// .qn-font-button-disabled-sure,
// .qn-font-button-disabled-sure:hover {
//   cursor: not-allowed;
//    background-image: linear-gradient(180deg, #2abbf5 0%, #0f8ced 100%) !important;
// }
</style>
