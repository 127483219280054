<template>
    <div class="com-img">
        <div class="img-content"  v-for="(item,index) in fileList" :key="index" v-if="fileList.length>0" :style="`height:${height};width:${width};`">
            <div class="cont">
                <!-- <img class="more-img" :src="`${item.url}`" v-if="item.type == 'img'"> -->
                <el-image :src='item.url' style="width:100%;height:100%;" fit='contain'>
                            <div slot="error" class="image-slot" style="width:100%;height:100%;">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                </el-image>
            </div>
            <!-- 删除icon -->
            <div class="btnBox">
                <!-- <i class="el-icon-zoom-in" @click="on_preview(item)"></i> -->
                <i @click="handleRemove(item)" class="el-icon-delete" v-if="!data.disabled"></i>
            </div>

      </div>
      <!-- list-type="picture-card" -->
        <el-upload v-if="fileList.length<limit"
            class="uploader"
            :action="img_action"
            :show-file-list="false"
            
            :limit="limit"
            accept="image/*"
            :disabled='data.disabled'
            :on-preview="handlePictureCardPreview"
            :http-request='upload_file'>
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible" size="tiny">
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
    </div>
</template>
<script>
import {file_delete} from '../../api/common_api.js'
const serverUrl =  "https://wrjc.wanrongly.com/"
export default {
    name:'comImg',
    props: {
      value: {
        default: '',
      },
      data: {
        type: Object,
        default: function () {
          return {};
        }
      },
      height:{
          type:String,
          default:'72px'
      },
      width:{
          type:String,
          default:'72px'
      },
    },
    data(){
        return{
            img_action:'/api/upload/imageUpload',
            dialogImageUrl: '',
            dialogVisible: false,
            fileList:[],
            fileEmit:[],
            ossConfig:'',
        }
    },
    created(){
        
    },
    methods:{
        
        value_init(){
            let vue = this;
            this.fileEmit = [];
            this.fileList = [];
            if(!this.value)return;
            if (typeof(this.value) === 'array') {
                    this.fileEmit = [];
                    this.fileList = [];
                    for (let index = 0; index < this.value.length; index++) {
                        let obj = {};
                        obj.url = `${serverUrl}${this.value[index]}`;
                        obj.realurl = this.value[index];
                        obj.type = 'img';
                        this.fileList.push(obj);
                        this.fileEmit.push(this.value[index]);
                    }
                    
                    this.$emit('input', this.fileEmit.join(','));
                }
            else if (typeof(this.value) === 'string') {
                if (this.value === '') {
                    this.fileEmit = [];
                    this.file_ids = [];
                }
                else {
                    let arr = this.value.split(',');
                    this.fileEmit = [];
                    this.fileList = [];
                    for (let index = 0; index < arr.length; index++) {
                        let obj = {};
                        obj.url = `${serverUrl}${arr[index]}`;
                        obj.type = 'img';
                        obj.realurl = arr[index];
                        this.fileList.push(obj);
                        this.fileEmit.push(arr[index]);
                    }
                    this.$emit('input', this.fileEmit.join(','));

                }
            }
        },
        handleRemove(data) {    
            console.log(data,'删除文件datatdatdatadtat')  
            let fileName = data.realurl.split("/")[1]
            file_delete({fileName:fileName}).then(res=>{
                console.log('删除文件',res);
                if(res.code == 200){
                    for (let index = 0; index < this.fileList.length; index++) {
                        if (data.realurl === this.fileList[index].realurl) {
                            this.fileList.splice(index, 1);
                            break;
                        }
                    }
                    for (let index = 0; index < this.fileEmit.length; index++) {
                        if (data.realurl === this.fileEmit[index]) {
                            this.fileEmit.splice(index, 1);
                            break;
                        }
                    }
                    this.$emit('input', this.fileEmit.join(','));
                }
            }).catch(err=>{
                console.log('ajax_err:',err)
            })
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        upload_file(upload){
            console.log(upload);
            let vue = this;
            let xmlhttp = new XMLHttpRequest();
            var data = new FormData();
            let name = upload.file.name
            data.append('file', upload.file);
            xmlhttp.open("POST", upload.action, true);
            xmlhttp.onreadystatechange = function () {
                if (xmlhttp.readyState == 4) {
                    if (xmlhttp.status == 200) {
                        let result = JSON.parse(xmlhttp.response);
                        console.log(result)
                        if(result.code=='200'){
                            console.log(result,'上传成功1111111111111111')
                            vue.$message({message:'上传成功',type:'success'});
                            let obj = {};
                            obj.url = `${serverUrl}${result.result}`;
                            obj.realurl = result.result;
                            obj.type = 'img';
                            vue.fileList.push(obj);
                            vue.fileEmit.push(result.result)
                            vue.$emit('input', vue.fileEmit.join(','));   
                            vue.$emit('expandData', name);   
                        } 
                    } else {
                        vue.$message({message:'上传错误',type:'error'});
                    }
                }
            }
            xmlhttp.upload.onprogress = function (event) {
            }
            xmlhttp.upload.onloadstart = function () {
            };
            xmlhttp.upload.onloadend = function () {
            };
            xmlhttp.onerror = function () {
                vue.list_show();
            };
            xmlhttp.send(data);
        }
    },
    computed:{
        limit: function () {
           return (this.data.expand && this.data.expand.limit) || 5;
        },
    },
    watch:{
        value(){
            this.value_init();
        },
        fileList:{
            handler(n){
            },
            deep:true,
        }
    }
}
</script>
<style lang='less'>
.com-img{
    width:100%;
    height:100%;
    .img-content {
        float: left;
        text-align: left;
        position: relative;
        display: inline-block;
        width: 72px;
        height: 72px;
        padding: 3px;
        margin:3px;
        position: relative;
        border:1px solid #DBDBDB;
        border-radius: 5px;
        transition: all .3s;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .12), 0 0 6px 0 rgba(0, 0, 0, .04);
        overflow: hidden;
        box-sizing: border-box;
        .cont{
            height: 100%;
        }
        .btnBox {
           display:none;
           width: 100%;
           height: 100%;
           position: absolute;
           top: 3px;
           left: 3px;
           color: #fff;
           text-align: center;
           z-index: 5;
           background-color: #DBDBDB;
           align-items: center;
           justify-content: center;
           i {
             line-height: 90px;
             cursor: pointer ;
             font-size: 14px;
             color: #fff;
          }
          .files{
            display: none;
          }
          .font_color{
            cursor: pointer;
            color:#24bbee;
          }
        }
    
    }
    .img-content:hover .btnBox {
        display: flex;
    }
    .uploader{
        width: 72px;
        height: 72px;
        .el-upload-list--picture-card{
            width: 100%;
            height:100%;
            li{
                float: left;
                width: 100%;
                height: 100%;
                margin-top:0;
            }
        }
        .el-upload,.el-upload--picture-card{
            width: 100%;
            height:100%;
            display: inline-block;
            background: none;
            color:#24BBEE;
            border:1px solid #000;
            line-height:80px;
            margin:3px;
            position: relative;
            .el-icon-plus{
                position: absolute;
                top:40%;
                left:40%;
                .el-icon-plus:before{
                    top:0;
                    left:0;
                    right:0;
                    bottom:0;
                    margin:auto;
                }
            }
        }

    }
    
}
</style>
<style lang="less">
.el-upload--picture-card{
    // width: 72px;
    // height:72px;
    // background: none;
    // color:#24BBEE;
    // border:1px solid #003e64;
    // line-height:80px;
    width: 100%;
    height: 100%;
    
}
</style>

