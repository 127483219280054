<template>
    <el-breadcrumb separator="/">
       <el-breadcrumb-item v-for="(item,index) in breadData" :to="{ path: item.path }" :key="index">{{item.name}}</el-breadcrumb-item>
    </el-breadcrumb>
</template>
<script>
export default {
    name:"comBreadcrumb",
    props:{
        breadData:{
            type: Array,
            default(){
                return [
                    {
                        name:"",
                        path:""
                    },
                ]
            }
        }
    },
    data(){
        return {}
    }
}
</script>