import { render, staticRenderFns } from "./com_select_radio.vue?vue&type=template&id=7153f192&scoped=true&"
import script from "./com_select_radio.vue?vue&type=script&lang=js&"
export * from "./com_select_radio.vue?vue&type=script&lang=js&"
import style0 from "./com_select_radio.vue?vue&type=style&index=0&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7153f192",
  null
  
)

export default component.exports